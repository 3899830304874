import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { useLabelContext } from "../../../Context";

export default function ViewChallan() {
  //   const { ChallanID } = useParams();
  const { ID } = useLabelContext();
  const ChallanID = ID;

  const [challanData, setChallanData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ChallanID) {
      sessionStorage.setItem("purchaseId", JSON.stringify(ChallanID));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetChallanDetails?ID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setLoading(false);
          setChallanData(data.ResultData);
          console.log(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setLoading(false);
          setChallanData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [ChallanID]);

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>View Delivery Chalan</h4>
            {/* <h6></h6> */}
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/delivery-challan" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          {loading ? (
            <p className="text-center my-5">
              <div class="containers m-auto">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </p>
          ) : (
            <form>
              <div className="row m-2">
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Customer</label>
                  <input
                    type="text"
                    className="form-control"
                    value={challanData?.cus_name}
                    placeholder="Customer"
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Project</label>
                  <input
                    type="text"
                    className="form-control"
                    value={challanData?.proj_name}
                    placeholder="Project Name"
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Date</label>
                  <input
                    type="text"
                    className="form-control"
                    value={new Date(challanData?.date_c)
                      .toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                      .replace(/\//g, "-")}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Site Latitude</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter site latitude"
                    value={challanData?.site_latitude}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Site Longitude</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter site longitude"
                    value={challanData?.site_longitude}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Distance</label>
                  <input
                    type="type"
                    className="form-control"
                    placeholder="Site distance will display here"
                    value={challanData?.distance}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Vehicle No</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter vehicle no"
                    value={challanData?.vehicle_no}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Delivery Person</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter driver name"
                    value={challanData?.driver_name}
                    readOnly
                  />
                </div>
                <div className="col-12 my-3">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      {challanData.commodity_details?.map((row, index) => (
                        <tr key={row.id}>
                          {/* <td>{index + 1}</td> */}
                          <td>{row.commodity_code}</td>
                          <td>{row.commodity_name}</td>
                          <td>{row.quantities}</td>
                          <td>{row.weight}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex flex-column col-lg-12 col-md-12">
                  <label className="form-label">Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    value={challanData?.description}
                    readOnly
                    placeholder="Challan desccription..."
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Base>
  );
}
