import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Base from "../../Config/Base";
import "../../../Assets/popup.css";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from "../../Config/Pagination";
import "../../Config/search.css";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";
import "../../Config/loader.css";
import Swal from "sweetalert2";
import AddContact from "./AddContact";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useLabelContext } from "../../../Context";

export default function Customers() {
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remidnerCusOBJ, setRemidnerCusOBJ] = useState([]);
  const [followupCusOBJ, setFollowupCusOBJ] = useState([]);
  const [addContactOBJ, setAddContactOBJ] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { setID } = useLabelContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setCustomersData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomersData([]); // Set an empty array to avoid errors
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const showReminderModal = (obj) => {
    setRemidnerCusOBJ(obj);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const filteredItems = customersData.filter(
  //   (item) =>
  //     (item.vat && item.vat.toString().includes(searchTerm.toString())) ||
  //     (item.company &&
  //       item.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.city &&
  //       item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //     (item.phonenumber &&
  //       item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()))
  // );


  const filteredItems = useMemo(() => {
    const results = customersData.filter(
      (item) =>
        (item.vat && item.vat.toString().includes(searchTerm.toString())) ||
        (item.company &&
          item.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.city &&
          item.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.phonenumber &&
          item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Reset current page if the search term changes
    setCurrentPage(1);

    return results;
  }, [customersData, searchTerm]);

  // Calculate indices for slicing data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const showFollowupModal = (obj) => {
    setFollowupCusOBJ(obj);
  };

  const showAddContactModal = (obj) => {
    setAddContactOBJ(obj);
  };

  const handleExcelDownload = () => {
    if (customersData.length === 0) {
      return; // Don't download if there is no data
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to worksheet format
    const wsData = customersData.map((item) => ({
      Company: item.company,
      "Date Created": formatDate(item.datecreated),
      City: item.city,
      State: item.state,
      Zipcode: item.zip,
      Website: item.website,
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "CustoemrsData");

    // Save the workbook to a file
    XLSX.writeFile(wb, "CustoemrsData.xlsx");
  };

  const handlePDFDownload = () => {
    const doc = new jsPDF();

    // Set properties for PDF
    doc.setProperties({
      title: "Customers Data",
      author: "Your Name",
      creator: "Your Application",
    });

    // Add data to PDF
    const tableData = []; // Your data array here

    // Add a header row
    const headers = [
      "Company",
      "Phone Number",
      "Created Date",
      "City",
      "State",
      "Zip",
      "Website",
    ];
    tableData.push(headers);

    // Add rows
    customersData.forEach((item) => {
      const row = [];
      row.push(
        item.company,
        item.phonenumber,
        formatDate(item.datecreated),
        item.city,
        item.state,
        item.zip,
        item.website
      );
      tableData.push(row);
    });

    // Generate table
    doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
    });

    // Save the PDF
    doc.save("CustomersData.pdf");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const toggleActiveStatus = async (userId, currentStatus) => {
    try {
      const response = await fetch(`${API_BASE_CRM_URL}ActiveCustomer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ UserId: userId, active: currentStatus ? 0 : 1 }),
      });

      const result = await response.json();

      if (result.Status) {
        // Update the local state to reflect the change
        setCustomersData((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.userid === userId
              ? { ...customer, active: currentStatus ? 0 : 1 }
              : customer
          )
        );
        Swal.fire("Success", "Customer status updated successfully", "success");
      } else {
        Swal.fire("Error", "Failed to update customer status", "error");
      }
    } catch (error) {
      console.error("Error updating customer status:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating customer status",
        "error"
      );
    }
  };

  const handleViewPurchaseOrders = (item) => {
    console.log(item);
    setID(item.userid);
  };

  return (
    <Base>
      <div className="page-header">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="page-title">
            <h4>Customers</h4>
            <h6>Manage your Customers</h6>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-3">
          <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pdf"
                onClick={handlePDFDownload}
              >
                <img src="assets/img/icons/pdf.svg" alt="PDF" />
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Excel"
                onClick={handleExcelDownload}
              >
                <img src="assets/img/icons/excel.svg" alt="Excel" />
              </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
                onClick={handleReload}
              >
                <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i className="fa-solid fa-chevron-up fs-6"></i>
              </a>
            </li>
            <li className="ms-2 ms-md-3">
              <Link className="btn btn-added" to="/add-customer">
                <i className="fa-solid fa-plus"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="card table-list-card">
        <div className="card-body">
          {/* Large devices */}
          <div className="table-responsve ">
            <table className="table datnew">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="no-sot">Action</th>
                  <th>Organization</th>
                  <th>Mobile</th>
                  <th>City</th>
                  <th>Vat</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={12} className="text-center">
                    <div class="containers m-auto">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </td>
                ) : (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="">
                        <div className="dropdown">
                          <a
                            className="action-set"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              to={`/view-customer`}
                              className="dropdown-item"
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-eye me-2"></i>View
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                item.active ? "" : "disabled"
                              }`}
                              to={`/edit-customer`}
                              onClick={() => handleViewPurchaseOrders(item)}
                            >
                              <i className="fa-regular fa-pen-to-square text-info me-2"></i>
                              Edit
                            </Link>
                            <a
                              className={`dropdown-item ${
                                item.active ? "" : "disabled"
                              }`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasContact"
                              aria-controls="offcanvasContact"
                              onClick={() => showAddContactModal(item)}
                            >
                              <i className="fa-regular fa-address-book me-2 text-success"></i>
                              Add Contact
                            </a>
                            <a
                              className={`dropdown-item ${
                                item.active ? "" : "disabled"
                              }`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasReminder"
                              aria-controls="offcanvasReminder"
                              onClick={() => showReminderModal(item)}
                            >
                              <i className="fa-solid fa-business-time me-2 text-info"></i>
                              Set Reminder
                            </a>
                            <a
                              className={`dropdown-item ${
                                item.active ? "" : "disabled"
                              }`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasFollowup"
                              aria-controls="offcanvasFollowup"
                              onClick={() => showFollowupModal(item)}
                            >
                              <i className="fa-solid fa-reply-all me-2"></i>
                              Followup
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                toggleActiveStatus(item.userid, item.active)
                              }
                            >
                              <i
                                className={`fa-solid fa-toggle-${
                                  item.active
                                    ? "off text-danger"
                                    : "on text-success"
                                } me-2`}
                              ></i>
                              {item.active ? "Deactivate" : "Activate"}
                            </a>
                            {/* <a className="dropdown-item confirm-text">
                                                        <i className="fa-regular fa-trash-can text-danger me-2"></i>Delete
                                                    </a> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link to={`/view-customer`}>
                          {item.company}
                        </Link>
                      </td>
                      <td>{item.phonenumber}</td>
                      <td>{item.city}</td>
                      <td>{item.vat ? item.vat : "N/A"}</td>
                      <td>
                        {item.active === 1 ? (
                          <span className="badge bg-outline-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bg-outline-danger">
                            Inactive
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={customersData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>

          {/* mobile view */}
          {/* <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {customersData && customersData.map((item, index) => (
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                <div className="employee-grid-profile">
                                    <div className="profile-head">
                                        {formatDate(item.datecreated)}
                                        <div className="profile-head-action">
                                            {item.active === 1 ? (
                                                    <span className="badge bg-outline-success w-auto me-1">Active</span>
                                                ) : (
                                                <span className="badge bg-outline-danger w-auto me-1">Inactive</span>
                                            )}
                                            <div className="dropdown profile-action">
                                                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg></a>
                                                <ul className="dropdown-menu">
                                                    <li className=" mt-2">
                                                        <Link className="dropdown-item" to={`/view-customer/${item.userid}`}>
                                                            <i className="fa-regular fa-eye me-2"></i> View
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className={`dropdown-item ${item.active ? '' : 'disabled'}`} to={`/edit-customer/${item.userid}`}>
                                                            <i className="fa-regular fa-pen-to-square me-2"></i>Edit
                                                        </Link>
                                                    </li>
                                                    <a 
                                                        className={`dropdown-item ${item.active ? '' : 'disabled'}`} 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#add-customer-contact"
                                                        onClick={() => showAddContactModal(item)}
                                                    >
                                                        <i className="fa-regular fa-address-book me-2 text-success"></i>Add Contact
                                                    </a>
                                                    <a 
                                                        className={`dropdown-item ${item.active ? '' : 'disabled'}`} 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#set-reminder"
                                                        onClick={() => showReminderModal(item)}
                                                    >
                                                        <i className="fa-solid fa-business-time me-2 text-info"></i>Set Reminder
                                                    </a>
                                                    <a 
                                                        className={`dropdown-item ${item.active ? '' : 'disabled'}`} 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#followup-lead"
                                                        onClick={() => showFollowupModal(item)}
                                                    >
                                                        <i className="fa-solid fa-reply-all me-2"></i>Followup
                                                    </a>
                                                    <a
                                                        className="dropdown-item"
                                                        onClick={() => toggleActiveStatus(item.userid, item.active)}
                                                    >
                                                        <i className={`fa-solid fa-toggle-${item.active ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                        {item.active ? 'Deactivate' : 'Activate'}
                                                    </a>
                                                   
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-info table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr className="text-start">
                                                    <td>
                                                        <h5 >{item.company}</h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-dark">{item.city}</h5>
                                                    </td>
                                                </tr>
                                                <tr className="text-start">
                                                    <td>
                                                        <h5>{item.vat}</h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-dark">{item.phonenumber}</h5>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
        </div>
      </div>

      <SetReminder cusOBJ={remidnerCusOBJ} />
      <FollowUp cusOBJ={followupCusOBJ} />
      <AddContact cusOBJ={addContactOBJ} />
    </Base>
  );
}
