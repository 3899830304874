import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faGear,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

export default function AddInventory() {
  const [userDetails, SetUserDetails] = useState([]);
  const [selectedItemid, setSelectedItemid] = useState("");
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedTaxid, setSelectedTaxid] = useState("");
  const [excelData, setExcelData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedinvoiceid, setSelectedInvoiceid] = useState("");
  const [selectedStatusid, setSelectedStatusid] = useState("");
  const [selectedStaffid, setSelectedStaffid] = useState("");

  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [invoicedata, setInvoiceData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);
  const [warehouesData, setWarehouseData] = useState([]);
  const [selectedWHid, setSelectedWHid] = useState("");
  const [description, setDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState({
    docu_number: "",
    accounting_date: "",
    voucher_date: "",
    invoices: "",
    customer_name: "",
    receiver: "",
    sales_person: "",
    invoice_number: "",
    address: "",
    item: "",
    items: [],
  });

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const data = await response.json();
        if (data.Status) {
          setData(data.ResultData);
        } else {
          console.error(`Fetched data from ${url} is not an array:`, data);
          setData([]);
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData(`${API_BASE_CRM_URL}GetCustomers`, setCustomersData);
    fetchData(`${API_BASE_CRM_URL}GetStaff`, setStaffData);
    fetchData(`${API_BASE_CRM_URL}GetTaxes`, setTaxesData);
    fetchData(`${API_BASE_CRM_URL}GetProducts`, setProductsData);
  }, []);



  //   useEffect(() => {
  //     console.log("cus:",selectedinvoiceid)
  //     if (selectedCustomerid) {
  //         const fetchData = async () => {
  //             try {
  //                 const response = await fetch(`${API_BASE_CRM_URL}GetCusByInventoryClientId?CusID=${selectedinvoiceid}`);
  //                 if (!response.ok) {
  //                     throw new Error("Network response was not ok");
  //                 }
  //                 const data = await response.json();
  //                 if (data.Status) {
  //                     setProjectsData(data.ResultData);
  //                 } else {
  //                     console.error("Fetched data is not an array:", data);
  //                     setProjectsData([]);
  //                 }
  //             } catch (error) {
  //                 console.error("Error fetching data:", error);
  //             }
  //         };
  //         fetchData();
  //     }
  // }, [selectedinvoiceid]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetInvoices`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setInvoiceData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setInvoiceData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setTaxesData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setTaxesData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const getWarwhouse = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetWarehouse`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setWarehouseData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setWarehouseData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    getWarwhouse();
    fetchData();
  }, []);

  useEffect(() => {
    console.log("cus:", selectedinvoiceid)
    if (selectedinvoiceid) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetCusByInventoryClientId?CusID=${selectedinvoiceid}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setProjectsData(data.ResultData);
            console.log(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedinvoiceid]);


  const projectOptions =
    invoicedata &&
    invoicedata.map((item) => ({
      value: item.id,
      label: item.clientid,
    }));

  console.log(projectOptions);

  const customerOptions =
    projectsData &&
    projectsData.map((item) => ({
      value: item.clientid,
      label: item.cus_name,
    }));
  console.log(customerOptions);





  const warwhouseOptions =
    warehouesData &&
    warehouesData.map((item) => ({
      value: item.warehouse_id,
      label: item.warehouse_name,
    }));



  const productOptions =
    productsData &&
    productsData.map((item) => ({
      value: item.id,
      label: item.description,
      rate: parseFloat(item.rate),
    }));

  const staffOptions =
    staffData &&
    staffData.map((item) => ({
      value: item.staffid,
      label: item.firstname + " " + item.lastname,
    }));

  const taxOptions =
    taxesData &&
    taxesData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const statusOptions = [
    { value: 1, label: "Draft" },
    { value: 2, label: "Sent" },
    { value: 5, label: "Expired" },
    { value: 3, label: "Declined" },
    { value: 4, label: "Accepted" },
  ];

  const handleCustomerChange = (selectedone) => {
    setSelectedCustomerid(selectedone);
  };

  const handleInvoiceChange = (selectedone) => {
    setSelectedInvoiceid(selectedone);
  };

  const handleStatusChange = (selectedone) => {
    setSelectedStatusid(selectedone);
  };

  const handleWarehouse = (selectedone) => {
    setSelectedWHid(selectedone);
  };
  const handleItemChange = (selectedone) => {
    setSelectedItemid(selectedone)
    console.log(selectedone);
    const status = productOptions.find((option) => option.value === selectedone);
    let result = status ? status.label : "";

    const itemprice = productsData.filter((item) => item.id === selectedone);

    const saleprice = itemprice.length > 0 ? itemprice[0].rate : 0;

    const discountPercentage = 10;

    const discountAmount = (saleprice * discountPercentage) / 100;

    const totalMoney = saleprice - discountAmount;

    console.log("Sale Price:", saleprice);
    console.log("Discount Amount:", discountAmount);
    console.log("Total Money after Discount:", totalMoney);


    setInventory({
      ...inventory,
      items: [
        ...inventory.items,
        {
          item: result,
          warehouse_name: "",
          avail_quantity: "",
          quantity: "",
          sale_price: saleprice,
          tax: "",
          sub_total: "",
          discount: "",
          dicount_money: "",
          total_payment: "",
        },
      ],
    });
    console.log(inventory);
  };

  const handleStaffChange = (selectedone) => {
    setSelectedStaffid(selectedone);
  };



  const addRow = (product) => {
    if (rows.some((row) => row.description === product.label)) {
      Swal.fire({
        title: "Product already added",
        text: "This product is already in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const newRow = {
      description: product.label,
      long_description: product.value || "",
      qty: 1,
      rate: product.rate || 0,
      tax: 9,
      amount: 0,
      item_order: rows.length + 1,
    };

    const subtotal = parseFloat(newRow.rate) * parseFloat(newRow.qty);
    const taxAmount = subtotal * (9 / 100);
    newRow.amount = (subtotal + taxAmount).toFixed(2);

    const updatedRows = [...rows, newRow];

    setRows(updatedRows);
  };

  const handleProductChange = (value, option) => {
    addRow(option);
  };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...inventory.items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value,
    };

    setInventory({ ...inventory, items: updatedItems });
  };
  const excdata = [
    [
      "Warehouse code ",
      "Warehouse name",
      "Warehouse address",
      "Order",
      "Display",
    ],
  ];

  const GenerateDownloadExcel = (e) => {
    const ws = XLSX.utils.aoa_to_sheet(excdata);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Student Data");


    // Generate buffer and save the file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, `warehouse.xlsx`);
  };

  useEffect(() => {
    const calculateAmounts = () => {
      let subTotal = 0;
      let totalTaxAmount = 0;
      let totalAmount = 0;

      rows.forEach((row) => {
        const rate = parseFloat(row.rate) || 0;
        const qty = parseFloat(row.qty) || 0;
        const taxPercentage = 9;

        const rowSubtotal = rate * qty;
        const taxAmount = rowSubtotal * (taxPercentage / 100);

        subTotal += rowSubtotal;
        totalTaxAmount += taxAmount;

        totalAmount += subTotal + totalTaxAmount;


        row.amount = (rowSubtotal + taxAmount).toFixed(2);
      });

      setSubTotalAmount(subTotal.toFixed(2));
      setTotalAmount(totalAmount.toFixed(2));
      setTotalTaxAmount(totalTaxAmount.toFixed(2));
    };

    calculateAmounts();
  }, [rows]);

  const handleDelete = async (index, id) => {
    const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(inventory);

    const inventorydata = {
      docu_number: inventory.docu_number,
      accounting_date: inventory.accounting_date,
      voucher_date: inventory.voucher_date,
      invoices: selectedinvoiceid,
      customer_code: selectedCustomerid,
      receiver: "",
      sales_person: selectedStaffid,
      invoice_number: inventory.invoice_number,
      address: inventory.address,
      items: inventory.items,
    };
    console.log(inventorydata);

    // try {
    //   const response = await fetch(`${API_BASE_CRM_URL}addinventory`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(inventorydata),
    //   });

    //   if (!response.ok) {
    //     throw new Error("Network response was not ok");
    //   }

    //   const result = await response.json();
    //   console.log(result);
    //   console.log(result, "rrrrrrrrrrrrrrrrrrr");
    //   if (result.Status) {
    //     setLoading(false);
    //     Swal.fire({
    //       title: `AddInventory  Successfully.`,
    //       icon: "success",
    //       customClass: {
    //         title: "swal-title",
    //         content: "swal-text",
    //       },
    //     }).then((result) => {
    //       if (result.isConfirmed || result.isDismissed) {
    //         // window.location.reload();
    //         // navigate("/purchase-order");
    //       }
    //     });
    //   } else {
    //     setLoading(false);
    //     Swal.fire({
    //       title: "Error",
    //       text: "Failed to submit request.",
    //       icon: "error",
    //       customClass: {
    //         title: "swal-title",
    //         content: "swal-text",
    //       },
    //     });
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error:", error);
    // }
  };

  const handleAddDownloadExcel = (e) => {
    e.preventDefault();
    if (true) {
      const link = document.createElement("a");
      // link.href = ${API_BASE_URL}${DOWNLD_EXCEL}?StoreId=${mainStoreId}&OrgId=${userDataSession.OrgId};
      // link.href = "https://library.ibizaccounts.in/";

      link.download = "filename.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("Main Store is Not Avaialable");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file

    ) {
      setSelectedFile(file);
      console.log(file);
    } else {
      alert("Please select a valid Excel file.");
    }
  };



  const handleAddInventory = (e) => {
    const { name, value } = e.target;
    setInventory((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(inventory);
  };

  const handleFileChanges = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel")
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });
        console.log(jsonData);

        const formattedData = jsonData.map((item) => {
          return {
            warehouse_code: item["Warehouse code"]
              ? item["Warehouse code"]
              : "",
            warehouse_name: item["Warehouse name"]
              ? item["Warehouse name"]
              : "",
            warehouse_address: item["Warehouse address"]
              ? item["Warehouse address"]
              : "",
            display: item.Display ? item.Display : 0,
          };
        });

        // STARTING CODE FOR CHUNK EXCEL
        const chunkSize = 50;
        if (formattedData.length > chunkSize) {
          alert(`Please enter not more than ${chunkSize} records.`);
          return;
        }
        // let index = 0;
        // const totalChunks = Math.ceil(formattedData.length / chunkSize);
        const totalChunks = 1;
        let accumulatedData = [];

        const processChunk = async (chunkIndex) => {
          if (chunkIndex >= totalChunks) {
            // setStatus("Completed");
            setExcelData(accumulatedData);
            return;
          }

          const startIndex = chunkIndex * chunkSize;
          const endIndex = Math.min(
            startIndex + chunkSize,
            formattedData.length
          );
          const chunk = formattedData.slice(startIndex, endIndex);

          // Simulate updating the chunk
          // console.log(
          //    Processing chunk ${chunkIndex + 1}/${totalChunks},
          //  chunk
          //);

          // Accumulate processed data
          accumulatedData = accumulatedData.concat(chunk);

          // // Update the index
          // index += 1;

          // Delay to simulate async processing

          await new Promise((resolve) => setTimeout(resolve, 1000));
          // console.log(excelData);

          // Process next chunk
          processChunk(chunkIndex + 1);
        };

        processChunk(0);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please select a valid Excel file.");
    }
  };

  const handleUploadExcel = async (e) => {
    e.preventDefault();
    console.log(excelData);

    try {
      const response = await fetch(`${API_BASE_CRM_URL}Addwarehouse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(excelData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      console.log(result, "rrrrrrrrrrrrrrrrrrr");
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `AddInventory  Successfully`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            // window.location.reload();
            // navigate("/purchase-order");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Base>
      <div className="page-heade d-flex justify-content-between align-items-center mb-3">
        <div className="add-iem me-auto">
          <div className="">
            <h4>Add Inventory</h4>
            <h6
              style={{ fontSize: "14px", color: "#495057", fontWeight: "400" }}
            >
              Enter fields for add Inventory
            </h6>
          </div>
        </div>
        <div className="table-top-hea ms-auto ">
          <button
            className="btn btn-primary mx-2"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAdd"
            aria-controls="offcanvasAdd"
            title="upload Excel"
          >
            <i className="fa-solid fa-plus"></i>
          </button>
          <Link to="/inventory">
            <button className="btn btn-primary">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </button>
          </Link>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
              <div className="col-md-4 col-lg-4 mb-2">
                <label className="form-label">Document Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="docu_number"
                  placeholder="Document Number"
                  value={inventory.docu_number}
                  onChange={handleAddInventory}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Accounting Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="accounting_date"
                  value={inventory.accounting_date}
                  onChange={handleAddInventory}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Voucher date</label>
                <input
                  type="date"
                  className="form-control"
                  name="voucher_date"
                  value={inventory.voucher_date}
                  onChange={handleAddInventory}
                />
              </div>

              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Invoices</label>
                <Select
                  showSearch
                  placeholder="Choose customer"
                  value={selectedinvoiceid}
                  onChange={handleInvoiceChange}
                  filterOption={(input, option) =>
                    (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={projectOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Customer Name</label>
                <Select
                  showSearch
                  placeholder="Choose Customer"
                  value={selectedCustomerid || null}
                  onChange={handleCustomerChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={customerOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="col-md-4 col-lg-4 mb-2">
                <label className="form-label">Receiver</label>
                <input
                  className="form-control"
                  type="text"
                  name="City"
                  placeholder=""
                  value={inventory.receiver}
                  onChange={handleAddInventory}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Sale Agent</label>
                <Select
                  showSearch
                  placeholder="Choose agent"
                  value={selectedStaffid || null}
                  onChange={handleStaffChange}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={staffOptions}
                  style={{ height: '2.4rem' }}
                />
              </div>

              <div className="col-md-4 col-lg-4 mb-2">
                <label className="form-label">Invoice Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="invoice_number"
                  placeholder="Invoice Number"
                  value={inventory.invoice_number}
                  onChange={handleAddInventory}
                />
              </div>
              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Address</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="address"
                  value={inventory.address}
                  onChange={handleAddInventory}
                  placeholder="Enter Address"
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Item</label>
                <Select
                  showSearch
                  placeholder="Select Item"
                  value={selectedItemid || null}
                  onChange={handleItemChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={productOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div
                className="col-12 my-3 table-responsive"
                style={{ maxHeight: "20rem", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top">
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>Warehouse Name</th>
                      <th>Availabele Quantity</th>
                      <th>Quantity</th>
                      <th>Sale Price</th>
                      <th>Tax</th>
                      <th>Sub Total</th>
                      <th>Discount</th>
                      <th>Discount(Money)</th>
                      <th>Total Payment</th>
                      <th>
                        <FontAwesomeIcon
                          icon={faGear}
                          className="text-primary fs-6"
                        />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {inventory.items.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product name"
                            style={{ width: "8rem" }}
                            value={row.item}
                            readOnly
                          />
                        </td>
                        <td>
                          <Select
                            showSearch
                            placeholder="Choose Warehouse"
                            value={row.warehouse_name || null}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={warwhouseOptions}
                            style={{ height: "2.4rem", width: "8rem" }}
                            onChange={(value, option) =>
                              handleInputChange(
                                index,
                                "warehouse_name",
                                option.label
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Available Quantity"
                            style={{ width: "7rem" }}
                            value={row.avail_quantity}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "avail_quantity",
                                e.target.value
                              )
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity"
                            style={{ width: "7rem" }}
                            value={row.quantity}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td>{row.sale_price}</td>
                        <td>
                          <Select
                            showSearch
                            placeholder="Choose Tax"
                            value={row.tax || null}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={taxOptions}
                            style={{ height: "2.4rem" }}
                            onChange={(value, option) =>
                              handleInputChange(index, "tax", option.label)
                            }
                          />
                        </td>
                        <td></td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Discount"
                            style={{ width: "7rem" }}
                            value={row.discount}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "discount",
                                e.target.value
                              )
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            className="btn"
                          // onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger fs-6"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12 my-1 text-end">
                <h5>Sub Total: {totalAmount}</h5>
              </div>
              <div className="col-12 my-1 text-end">
                <h5>Total Discount: {totalAmount}</h5>
              </div>
              <div className="col-12 my-1 text-end">
                <h5>Total Amount: {totalAmount}</h5>
              </div>
              <div className="col-12 my-1 text-end">
                <h5>
                  Shipping Fee:
                  <input type="number" className="" />
                </h5>
              </div>
              <div className="col-12 my-1 text-end">
                <h5>Total Payment: {totalAmount}</h5>
              </div>

              <div className="d-flex flex-column col-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          className="offcanvas offcanvas-end custom-offcanvas"
          tabIndex="-1"
          id="offcanvasAdd"
          aria-labelledby="offcanvasRightLabel"
        >
          <style>
            {`
                        .offcanvas.custom-offcanvas {
                            width: 450px !important; /* Adjust this as needed */
                        }

                        @media (max-width: 576px) {
                            .offcanvas.custom-offcanvas {
                            width: 70% !important; /* Adjust the percentage as needed */
                            }
                        }
                        `}
          </style>
          <div className="offcanvas-header">
            <h4>Upload Excel</h4>
            <FontAwesomeIcon
              icon={faDownload}
              className="mx-2 text-success"
              onClick={GenerateDownloadExcel}
            />
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            {/* <p className='fw-semibold'>Fill out the form to add a new product</p> */}
          </div>
          <div className="offcanvas-body ms-2">
            <form onSubmit={handleUploadExcel}>
              <div className="mb-2">
                <label className="form-label">
                  File<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  accept=".xlsx, .xls"
                  // value={formValues.name}
                  onChange={handleFileChanges}
                  required
                />
              </div>

              <div className="text-center m-auto">
                <button
                  type="submit"
                  className="btn btn-primary col-7"
                  disabled={loading}
                >
                  {loading ? "Adding..." : "Add Product"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Base>
  );
}
