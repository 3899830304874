
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


export default function ViewLogisticRate({ logisticRateObj }) {

    const [logisticRate, setLogisticRate] = useState([]);

    useEffect(() => {
        setLogisticRate(logisticRateObj);
    }, [logisticRateObj]);
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        
        <div 
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasView"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                <div className="page-title d-flex justify-content-between align-items-center">
                    <h4>View Logistic Rate</h4>
                    {logisticRate?.IsActive === 1 ? (
                        <span className="badge bg-outline-success">Active</span>
                    ) : (
                        <span className="badge bg-outline-danger">Inactive</span>
                    )}
                </div>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <span className="ms-3">{formatDate(logisticRate?.createdon)}</span>
            <div className="offcanvas-body ms-2">
                <form action="brand-list.html">
                    <div className="row">
                        <div className="mb-3 col-6">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="Title"
                                className="form-control"
                                placeholder="Name of Product"
                                value={logisticRate?.name}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Min Distance</label>
                            <input
                                type="text"
                                name="Title"
                                className="form-control"
                                placeholder="Min Distance"
                                value={logisticRate?.min_distance}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Max Distance</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Max Distance"
                                value={logisticRate?.max_distance}
                                readOnly
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Weight</label>
                            <input
                                type="text"
                                name="Title"
                                className="form-control"
                                placeholder="Quantity"
                                value={logisticRate?.weight}
                                readOnly
                            />
                        </div>
                        {/* <div className="mb-3 col-6">
                            <label className="form-label">Units</label>
                            <input
                                type="text"
                                name="Title"
                                className="form-control"
                                placeholder="Units"
                                value={logisticRate?.units}
                                readOnly
                            />
                        </div> */}
                        <div className="mb-3 col-6">
                            <label className="form-label">Price</label>
                            <input
                                type="text"
                                name="Title"
                                className="form-control"
                                placeholder="Price"
                                value={logisticRate?.price}
                                readOnly
                            />
                        </div>
                        
                        <div className="mb-3 col-12">
                            <label className="form-label">Notes</label>
                            <textarea className="form-control" placeholder="Notes..." readOnly value={logisticRate?.notes || ''}></textarea>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
                   
    );
}

ViewLogisticRate.propTypes = {
    logisticRateObj: PropTypes.array.isRequired,
};
  