
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { useNavigate } from "react-router-dom";


export default function AddContact({ leadOBJ }) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        LeadId: leadOBJ?.id || "",
        CreatedBy: 1,
        FullName: "",
        Email: "",
        PhoneNumber: "",
        Designation: ""
    });

    useEffect(() => {
        if (leadOBJ) {
            setFormData((prevData) => ({
                ...prevData,
                LeadId: leadOBJ.id || prevData.LeadId
            }));
        }
    }, [leadOBJ]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {

        console.log(formData, 'data to api')
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_CRM_URL}AddLeadContact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Contact Added Successfully.`,
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();

                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Request Submit.",
                    icon: "error",
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
    };

    return (

        <div
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasContact"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                <h4>Add Contact</h4>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ms-2">
                <form>
                    <div className="row">
                        <div className="mb-3 col-12">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="FullName"
                                placeholder="Enter Contact Name"
                                value={formData.FullName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-12">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                name="Email"
                                placeholder="Enter Contact Email"
                                value={formData.Email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-12">
                            <label className="form-label">Phone</label>
                            <input
                                type="text"
                                className="form-control"
                                name="PhoneNumber"
                                placeholder="Enter Contact Mobile"
                                value={formData.PhoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-12">
                            <label className="form-label">Designation</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Designation"
                                placeholder="Enter designation"
                                value={formData.Designation}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-2 m-auto mt-3">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
}

AddContact.propTypes = {
    leadOBJ: PropTypes.array.isRequired,
};

