import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import "../../Config/swal.css";
import { Select, Tooltip } from "antd";
import { useLabelContext } from "../../../Context";

const EditTask = () => {
  //   const { TaskId } = useParams();
  const { ID } = useLabelContext();
  const TaskId = ID;

  const navigate = useNavigate();
  const [currentStaffId, setCurrentStaffId] = useState();

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      setCurrentStaffId(parsedUserDetails.staffid);
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const [taskData, setTaskData] = useState({
    id: TaskId,
    name: "",
    description: "",
    priority: "",
    startdate: "",
    duedate: "",
    repeat_every: "",
    status: "",
    rel_id: "",
    rel_type: "",
    is_public: "",
    billable: "",
    hourly_rate: "",
    assignments: "",
    followers: "",
  });

 
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [selectedFollower, setSelectedFollower] = useState([]);
  const [selectedRelatedTo, setSelectedRelatedTo] = useState(0); 

  const [relatedToData, setRelatedToData] = useState(""); 
  const [selectedRelatedToData, setSelectedRelatedToData] = useState("");
  const [assigneeData, setAssigneeData] = useState("");
  const [followersData, setFollowersData] = useState("");

  const [loading, setLoading] = useState(false);



  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setTaskData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  //assignee logic start
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setAssigneeData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setAssigneeData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const AssigneeOptions =
    assigneeData &&
    assigneeData.map((item) => ({
      value: item.staffid,
      label: item.firstname + " " + item.lastname,
    }));

  const assigneeList = selectedAssignee.map((staffid) => ({
    staffid,
    assigned_from: currentStaffId,
  }));

 

  //follower logic start
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setFollowersData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setFollowersData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const FollowersOptions =
    followersData &&
    followersData.map((item) => ({
      value: item.staffid,
      label: `${item.firstname} ${item.lastname}`,
    }));

  const followerList = selectedFollower.map((follower) => ({
    staffid: follower,
  }));


  const handleFollowerChange = (selectedone) => {
    setSelectedFollower(selectedone);
    const updatedFollowers = selectedFollower.map((id) => ({ staffid: id }));
    setTaskData((prevData) => ({
      ...prevData,
      followers: updatedFollowers,
    }));
  };
  const handleAssigneeChange = (selectedone) => {
    setSelectedAssignee(selectedone);
    const updatedassignees = selectedAssignee.map((id) => ({ staffid: id }));
    setTaskData((prevData) => ({
      ...prevData,
      assignments: updatedassignees,
    }));
  };

  useEffect(() => {
    if (TaskId) {
      sessionStorage.setItem("purchaseId", JSON.stringify(TaskId));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetTaskByIdnew?ID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          const taskDetails = data.ResultData[0];

          console.log(taskDetails);

          const assigneeJson = {
            assigned_staff_ids_and_names:
              taskDetails.assigned_staff_ids_and_names,
            assigned_from: taskDetails.assigned_from,
          };

          const assigneeEntries = assigneeJson.assigned_staff_ids_and_names
            ? assigneeJson.assigned_staff_ids_and_names.split(",")
            : []; 

          const finalAssignee = assigneeEntries.map((entry) => {
            const [staffid] = entry.split(":");
            return {
              staffid: parseInt(staffid.trim(), 10),
              assigned_from: taskDetails.assigned_from,
            };
          });

          const followerJson = {
            follower_staff_ids_and_names:
              taskDetails.follower_staff_ids_and_names,
          };

          // Check if the string is empty
          const followerEntries = followerJson.follower_staff_ids_and_names
            ? followerJson.follower_staff_ids_and_names.split(",")
            : [];

          // Map through each entry and extract the staffid
          const finalFollower = followerEntries.map((entry) => {
            const [staffid] = entry.split(":");
            return {
              staffid: parseInt(staffid.trim(), 10), // Parse staffid as integer
            };
          });

          setTaskData({
            id: TaskId,
            name: taskDetails.name,
            description: taskDetails.description,
            priority: taskDetails.priority,
            startdate: formatDate(taskDetails.startdate),
            duedate: formatDate(taskDetails.duedate),
            repeat_every: taskDetails.repeat_every,
            status: taskDetails.status,
            rel_id: taskDetails.rel_id,
            rel_type: taskDetails.rel_type,
            is_public: taskDetails.is_public,
            billable: taskDetails.billable,
            hourly_rate: taskDetails.hourly_rate,
            assignments: finalAssignee,
            followers: finalFollower,
          });
        } else {
          setTaskData({});
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [TaskId]);

  useEffect(() => {
    if (selectedRelatedTo === 1) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetProjects`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setRelatedToData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setRelatedToData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else if (selectedRelatedTo === 2) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetInvoices`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setRelatedToData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setRelatedToData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else if (selectedRelatedTo === 3) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setRelatedToData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setRelatedToData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else if (selectedRelatedTo === 7) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetExpenses`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setRelatedToData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setRelatedToData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else if (selectedRelatedTo === 8) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetLeads`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setRelatedToData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setRelatedToData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } else {
      console.log("Selected ID is out of range");
    }
  }, [selectedRelatedTo]);

  const relatedOptions =
    relatedToData &&
    relatedToData
      .map((item) => {
        if (selectedRelatedTo === 1) {
          return {
            value: item.id,
            label: item.name,
          };
        } else if (selectedRelatedTo === 2) {
          return {
            value: item.id,
            label: item.clientid,
          };
        } else if (selectedRelatedTo === 3) {
          return {
            value: item.userid,
            label: item.company,
          };
        } else if (selectedRelatedTo === 7) {
          return {
            value: item.id,
            label: item.expense_name,
          };
        } else if (selectedRelatedTo === 8) {
          return {
            value: item.id,
            label: item.name,
          };
        } else {
          console.log("Selected ID is out of range");
          return null; 
        }
      })
      .filter((option) => option !== null); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(taskData, "data sendin to api");
    try {
      const response = await fetch(`${API_BASE_CRM_URL}updateTask`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(taskData),
      });
      if (!response.ok) {
        throw new Error("Failed to update customer data");
      }
      const result = await response.json();
      console.log(result, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
      setLoading(false);

      if (result.Status) {
        Swal.fire({
          title: `Task Updated Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/tasks");
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setLoading(false);
    }
  };

  const handlePriorityChange = (selectedOption) => {
    setTaskData((prevData) => ({
      ...prevData,
      priority: selectedOption,
    }));
  };

  const handleRepeatEveryChange = (selectedOption) => {
    setTaskData((prevData) => ({
      ...prevData,
      repeat_every: selectedOption,
    }));
  };

  const handleStatusChange = (selectedOption) => {
    setTaskData((prevData) => ({
      ...prevData,
      status: selectedOption,
    }));
  };

  const handleRelatedTo = (selectedone) => {
    console.log(selectedone);
    setSelectedRelatedTo(selectedone);
    setTaskData((prevData) => ({
      ...prevData,
      rel_id: selectedone,
    }));
  };

  const handleSelectedRelatedToData = (selectedone) => {
    console.log(selectedone);
    setSelectedRelatedToData(selectedone);
    setTaskData((prevData) => ({
      ...prevData,
      rel_type: selectedone,
    }));
  };

  const priorityOptions = [
    { value: 1, label: "Urgent" },
    { value: 2, label: "High" },
    { value: 3, label: "Medium" },
    { value: 4, label: "Low" },
  ];
  const repeatEveryOptions = [
    { value: 1, label: "Week" },
    { value: 2, label: "2 Weeks" },
    { value: 3, label: "1 Month" },
    { value: 4, label: "2 Months" },
    { value: 5, label: "3 Months" },
    { value: 6, label: "6 Months" },
    { value: 7, label: "1 Year" },
    // { "value": 8, "label": "Custom" },
  ];

  const statusOptions = [
    { value: 1, label: "Not Started" },
    { value: 2, label: "In Progress" },
    { value: 3, label: "Awaiting Feedback" },
    { value: 4, label: "Testing" },
    { value: 5, label: "Complete" },
  ];

  const relatedToOptions = [
    { value: 1, label: "project" },
    { value: 2, label: "Invoice" },
    { value: 3, label: "Customer" },
    { value: 7, label: "Expense" },
    { value: 8, label: "Lead" },
    { value: 0, label: " " },
  ];

  const selectedOption = relatedToOptions.find(
    (option) => Number(option.value) === Number(taskData.rel_type)
  );
  

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>Edit Task</h4>
            <h6>Subject here</h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/tasks" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
              <div class="custom-filed my-2 ">
                <div class="input-block add-lists d-flex">
                  <label className="checkboxs">
                    <input
                      type="checkbox"
                      name="is_public"
                      checked={taskData.is_public === 1}
                      onChange={handleChange}
                    />
                    <span className="checkmarks mx-1"></span>
                    <Tooltip
                      placement="bottom"
                      title="If you set this task to public it will be visible to all staff members. Otherwise, it will only be visible to assignees, followers, the creator, or administrators."
                    >
                      <p className="text-dark">Public</p>
                    </Tooltip>
                  </label>
                  <label className="checkboxs">
                    <input
                      type="checkbox"
                      name="billable"
                      checked={taskData.billable === 1}
                      onChange={handleChange}
                    />
                    <span className="checkmarks mx-1"></span>Billable
                  </label>
                </div>

                {/* <div class="drag-drop text-center mb-4">
                                    <div class="upload">
                                        <a href="#"><img src="assets/img/icons/drag-drop.svg" alt=""/></a>
                                        <p>Drag and drop a <a href="#">file to upload</a></p>
                                    </div>
                                    <input type="file" multiple=""/>
                                </div> */}
              </div>

              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Subject</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={taskData.name}
                  onChange={handleChange}
                  placeholder="Enter Subject"
                  required
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Hourly Rate</label>
                <input
                  className="form-control"
                  type="number"
                  name="hourly_rate"
                  value={taskData.hourly_rate}
                  onChange={handleChange}
                  placeholder="Enter Hourly Rate "
                  required
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Priority</label>
                <Select
                  showSearch
                  placeholder="Select Priority"
                  value={taskData.priority || null}
                  onChange={handlePriorityChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={priorityOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="startdate"
                  value={taskData.startdate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Due Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="duedate"
                  value={taskData.duedate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Repeat Every</label>
                <Select
                  showSearch
                  placeholder="Selected Repetition"
                  value={taskData.repeat_every || null}
                  onChange={handleRepeatEveryChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={repeatEveryOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Related To</label>
                <Select
                  showSearch
                  placeholder="Select type"
                  value={selectedOption || null}
                  onChange={handleRelatedTo}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={relatedToOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div
                className={`d-flex flex-column col-lg-4 col-md-4 mb-2 ${selectedRelatedTo === 0 ? "d-none" : "d-block"
                  }`}
              >
                <label className="form-label">
                  {(() => {
                    switch (selectedRelatedTo) {
                      case 1:
                        return <>project</>;
                      case 2:
                        return <>Invoice</>;
                      case 3:
                        return <>Customer</>;
                      case 4:
                        return <>Estimate</>;
                      case 5:
                        return <>Contract</>;
                      case 6:
                        return <>Ticket</>;
                      case 7:
                        return <>Expense</>;
                      case 8:
                        return <>Lead</>;
                      case 9:
                        return <>Proposal</>;
                      case 10:
                        return <>Inventory receiving voucher</>;
                      case 11:
                        return <>Inventory delivery voucher</>;
                      default:
                        return <>invalid</>;
                    }
                  })()}
                </label>
                <Select
                  showSearch
                  placeholder="Select type"
                  onChange={handleSelectedRelatedToData}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={relatedOptions}
                  style={{ height: "2.4rem" }}
                // value={taskData.rel_id || null}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Assignees</label>
                <Select
                  showSearch
                  placeholder="Select Assignee"
                  value={
                    taskData.assignments.length > 0
                      ? taskData?.assignments?.map(
                          (assignee) => assignee.staffid
                        )
                      : []
                  }
                  onChange={handleAssigneeChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={AssigneeOptions}
                  style={{ height: "2.4rem" }}
                  mode="multiple"
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Followers</label>
                <Select
                  showSearch
                  placeholder="Select Follower"
                  onChange={handleFollowerChange}
                  value={
                    taskData.followers.length > 0
                      ? taskData?.followers?.map((follower) => follower.staffid)
                      : []
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={FollowersOptions}
                  style={{ height: "2.4rem" }}
                  mode="multiple"
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Status</label>
                <Select
                  showSearch
                  placeholder="Select Priority"
                  value={taskData.status || null}
                  onChange={handleStatusChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={statusOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={taskData.description || null}
                  onChange={handleChange}
                  placeholder="Enter notes..."
                  name="description"
                />
              </div>
              <div className="d-flex flex-column col-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
};

export default EditTask;
