import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { API_BASE_CRM_URL } from "../../Config/Config";

export default function EditFollowUp({ folupObj }) {
  console.log(folupObj)
  const [formData, setFormData] = useState({
    Title: "",
    Date: "",
    FollowupType: "",
    description: "",
    reminderCheck: false,
  });

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (folupObj) {
      setFormData({
        Title: folupObj.Title || "",
        Date: formatDateForInput(folupObj.Date) || "",
        FollowupType: folupObj.FollowupType || "",
        description: folupObj.Description || "",
      });
    }
  }, [folupObj]);
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${API_BASE_CRM_URL}UpdateFollowup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          Date: formatDateForSubmission(formData.Date),
          ID: folupObj.ID,
        }),
      });

      const result = await response.json();
      if (result.Status) {
        setLoading(false);
        Swal.fire({
            title: `Followup Updated Successfully.`,
            icon: "success",
        }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
                window.location.reload();
            }
        });
    } else {
        setLoading(false);
        Swal.fire({
            title: "Error",
            text: "Failed to submit request.",
            icon: "error",
        });
    }

      if (response.ok) {
        Swal.fire("Success!", "Follow-up updated successfully", "success");
      } else {
        Swal.fire("Error!", result.error || "An error occurred", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred while updating the follow-up", "error");
    } finally {
      setLoading(false);
    }
  };

  const formatDateForInput = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formatDateForSubmission = (inputDate) => {
    const [date, time] = inputDate.split('T');
    const formattedDate = `${date} ${time}:00`; // Adds seconds
    return formattedDate;
  };
  

  return (
    <div 
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasEdit"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                <div className="page-title d-flex">
                  <h4>Edit Followup</h4>
                </div>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body ms-2">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3 col-12">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        name="Title"
                        className="form-control"
                        placeholder="Enter title"
                        value={formData.Title}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="form-label">Date</label>
                      <input
                        type="datetime-local"
                        name="Date"
                        className="form-control"
                        value={formData.Date}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3 col-12">
                      <label className="form-label">Type</label>
                      <select
                        name="FollowupType"
                        className="form-select"
                        value={formData.FollowupType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Choose type</option>
                        <option value="Call">Call</option>
                        <option value="Email">Email</option>
                        <option value="Visit">Visit</option>
                        <option value="Whatsapp">Whats app</option>
                      </select>
                    </div>
                  
                  </div>
                  <div className="mb-3 col-12">
                    <label className="form-label">Notes</label>
                    <textarea
                      name="description"
                      className="form-control"
                      placeholder="Enter Notes..."
                      value={formData.description}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <button
                      type="button"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
  );
}

EditFollowUp.propTypes = {
  folupObj: PropTypes.array.isRequired,
};
