import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "../../Config/swal.css";
import { useLabelContext } from "../../../Context";

export default function EditPurchaseOrder() {
  //   const { PurchaseID } = useParams();
  const { ID } = useLabelContext();
  const PurchaseID = ID;

  const [userDetails, SetUserDetails] = useState([]);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [estimatePOData, setEstimatePOData] = useState({});
  const [itemsPOData, setItemsPOData] = useState([]);

  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedprojectid, setSelectedProjectid] = useState("");
  const [selectedStatusid, setSelectedStatusid] = useState("");
  const [selectedStaffid, setSelectedStaffid] = useState("");

  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    id: PurchaseID,
    sent: 1,
    datesend: "",
    clientid: "",
    project_id: "",
    number: "",
    prefix: "",
    number_format: "",
    date: "",
    expirydate: "",
    currency: "",
    subtotal: "",
    total_tax: "",
    total: "",
    adjustment: 0.0,
    addedfrom: "",
    status: "",
    clientnote: "",
    adminnote: "",
    discount_percent: "",
    discount_total: "",
    discount_type: "",
    reference_no: "",
    sale_agent: "",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_country: "",
    shipping_street: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip: "",
    shipping_country: "",
    show_shipping_on_estimate: true,
    products: [],
  });

  useEffect(() => {
    if (PurchaseID) {
      sessionStorage.setItem("purchaseId", JSON.stringify(PurchaseID));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_BASE_CRM_URL}GetPurchaseDetails?ID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data)
        if (data.Status) {
          setLoading(false);
          setEstimatePOData(data.ResultData.estimate);
          setItemsPOData(data.ResultData.items);
          setFormValues({
            id: data.ResultData.estimate.id || "",
            sent: data.ResultData.estimate.sent || "",
            datesend: data.ResultData.estimate.datesend || "",
            clientid: data.ResultData.estimate.clientid || "",
            project_id: data.ResultData.estimate.project_id || "",
            number: data.ResultData.estimate.number || "",
            prefix: data.ResultData.estimate.prefix || "",
            number_format: data.ResultData.estimate.number_format || "",
            date: formatDate(data.ResultData.estimate.date || ""),
            expirydate: formatDate(data.ResultData.estimate.expirydate || ""),
            currency: data.ResultData.estimate.currency || "",
            subtotal: data.ResultData.estimate.subtotal || "",
            total_tax: data.ResultData.estimate.total_tax || "",
            total: data.ResultData.estimate.total || "",
            adjustment: data.ResultData.estimate.adjustment || "",
            addedfrom: data.ResultData.estimate.addedfrom || "",
            status: data.ResultData.estimate.status || "",
            clientnote: data.ResultData.estimate.clientnote || "",
            adminnote: data.ResultData.estimate.adminnote || "",
            discount_percent: data.ResultData.estimate.discount_percent || "",
            discount_total: data.ResultData.estimate.discount_total || "",
            discount_type: data.ResultData.estimate.discount_type || "",
            reference_no: data.ResultData.estimate.reference_no || "",
            sale_agent: data.ResultData.estimate.sale_agent || "",
            billing_street: data.ResultData.estimate.billing_street || "",
            billing_city: data.ResultData.estimate.billing_city || "",
            billing_state: data.ResultData.estimate.billing_state,
            billing_zip: data.ResultData.estimate.billing_zip || "",
            billing_country: data.ResultData.estimate.billing_country || "",
            shipping_street: data.ResultData.estimate.shipping_street || "",
            shipping_city: data.ResultData.estimate.shipping_city || "",
            shipping_state: data.ResultData.estimate.shipping_state || "",
            shipping_zip: data.ResultData.estimate.shipping_zip || "",
            shipping_country: data.ResultData.estimate.shipping_country || "",
            show_shipping_on_estimate:
              data.ResultData.estimate.show_shipping_on_estimate || "",
          });

          setSelectedCustomerid(data.ResultData.estimate.clientid || "");
          setSelectedProjectid(data.ResultData.estimate.project_id || "");
          setSelectedStaffid(data.ResultData.estimate.sale_agent || "");
          setSelectedStatusid(data.ResultData.estimate.status || "");
        } else {
          setLoading(false);
          console.error("Fetched data is not an array:", data);
          setEstimatePOData([]);
          setItemsPOData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [PurchaseID]);

  // const defaultTax = taxesData.find(item => item.taxrate === 9);

  // Initialize the selected tax with the default tax (if it exists)
  //   const [selectedTax, setSelectedTax] = useState(defaultTax ? defaultTax.id : null);

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomerid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      clientid: selectedOption,
    }));
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProjectid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      project_id: selectedOption,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //get customers
  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const data = await response.json();
        if (data.Status) {
          setData(data.ResultData);
        } else {
          console.error(`Fetched data from ${url} is not an array:`, data);
          setData([]);
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData(`${API_BASE_CRM_URL}GetCustomers`, setCustomersData);
    fetchData(`${API_BASE_CRM_URL}GetStaff`, setStaffData);
    fetchData(`${API_BASE_CRM_URL}GetTaxes`, setTaxesData);
    fetchData(`${API_BASE_CRM_URL}GetProducts`, setProductsData);
  }, []);

  //get projects by Customer ID
  useEffect(() => {
    if (selectedCustomerid) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            console.log(data.ResultData)
            setProjectsData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedCustomerid]);

  const customerOptions =
    customersData &&
    customersData.map((item) => ({
      value: item.userid,
      label: item.company,
    }));

  const projectOptions =
    projectsData &&
    projectsData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const productOptions =
    productsData &&
    productsData.map((item) => ({
      value: item.long_description,
      label: item.description,
      rate: parseFloat(item.rate),
    }));

  const staffOptions =
    staffData &&
    staffData.map((item) => ({
      value: item.staffid,
      label: item.firstname + " " + item.lastname,
    }));

  // const taxOptions = taxesData && taxesData.map((item) => ({
  //     key: item.id,
  //     value: item.taxrate,
  //     label: item.name + " " + item.taxrate,
  // }));

  const statusOptions = [
    { value: 1, label: "Draft" },
    { value: 2, label: "Sent" },
    { value: 5, label: "Expired" },
    { value: 3, label: "Declined" },
    { value: 4, label: "Accepted" },
  ];

  const handleStatusChange = (selectedone) => {
    setSelectedStatusid(selectedone);
    setFormValues((prevData) => ({
      ...prevData,
      status: selectedone,
    }));
  };

  const handleStaffChange = (selectedone) => {
    setSelectedStaffid(selectedone);
    setFormValues((prevData) => ({
      ...prevData,
      sale_agent: selectedone,
    }));
  };

  // useEffect(() => {
  //     if (itemsPOData && itemsPOData.length > 0) {
  //         setRows(itemsPOData.map(item => ({
  //             id: item.id || null,
  //             rel_id: item.rel_id || null,
  //             description: item.description,
  //             long_description: item.long_description || '',
  //             qty: item.qty || '',
  //             rate: item.rate || '',
  //             tax: item.tax || '',
  //             amount: 10,
  //             item_order: item.item_order || itemsPOData.length + 1
  //         })));
  //     }
  // }, [itemsPOData]);

  useEffect(() => {
    if (itemsPOData && itemsPOData.length > 0) {
      setRows(
        itemsPOData.map((item) => {
          const rate = parseFloat(item.rate) || 0;
          const qty = parseFloat(item.qty) || 0;
          const taxPercentage = parseFloat(item.tax) || 9;

          const subtotal = rate * qty;
          const taxAmount = subtotal * (taxPercentage / 100);
          const totalAmount = subtotal + taxAmount;

          return {
            id: item.id || null,
            rel_id: item.rel_id || null,
            description: item.description,
            long_description: item.long_description || "",
            qty: item.qty || "",
            rate: item.rate || "",
            tax: item.tax || "",
            amount: totalAmount,
            item_order: item.item_order || itemsPOData.length + 1,
          };
        })
      );
    }
  }, [itemsPOData]);

  // const addRow = (product) => {
  //     if (rows.some(row => row.description === product.label)) {
  //         Swal.fire({
  //             title: "Product already added",
  //             text: "This product is already in the list.",
  //             icon: "warning",
  //             confirmButtonText: "OK"
  //         });
  //         return;
  //     }

  //     const newRow = {
  //         description: product.label,
  //         long_description: product.value || '',
  //         qty: '',
  //         rate: product.rate,
  //         tax: '',
  //         amount: 0,
  //         item_order: rows.length + 1
  //     };

  //     setRows([...rows, newRow]);
  // };

  const addRow = (product) => {
    if (rows.some((row) => row.description === product.label)) {
      Swal.fire({
        title: "Product already added",
        text: "This product is already in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const newRow = {
      description: product.label,
      long_description: product.value || "",
      qty: 1,
      rate: product.rate || 0,
      tax: 9, // Fixed tax rate 9%
      amount: 0,
      item_order: rows.length + 1,
    };

    // Calculate initial amount
    const subtotal = parseFloat(newRow.rate) * parseFloat(newRow.qty);
    const taxAmount = subtotal * (9 / 100);
    newRow.amount = (subtotal + taxAmount).toFixed(2);

    const updatedRows = [...rows, newRow];

    setRows(updatedRows);

    // Update total amount
    const updatedTotal = updatedRows.reduce(
      (sum, row) => sum + parseFloat(row.amount),
      0
    );
    setEstimatePOData((prevData) => ({
      ...prevData,
      total: updatedTotal.toFixed(2),
    }));
  };

  const handleProductChange = (value, option) => {
    addRow(option);
  };

  // const handleInputChange = (index, field, value) => {
  //     setRows(prevRows => {
  //         if (!prevRows[index]) return prevRows;  // Defensive check
  //         const updatedRows = [...prevRows];
  //         const updatedRow = { ...updatedRows[index], [field]: value };
  //         updatedRows[index] = updatedRow;
  //         return updatedRows;
  //     });
  // };

  const handleInputChange = (index, field, value) => {
    setRows((prevRows) => {
      if (!prevRows[index]) return prevRows; 
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index], [field]: value };

      const rate = parseFloat(updatedRow.rate) || 0;
      const qty = parseFloat(updatedRow.qty) || 0;
      const taxPercentage = 9; 

      const subtotal = rate * qty;
      const taxAmount = subtotal * (taxPercentage / 100);
      const totalAmount = subtotal + taxAmount;

      updatedRow.amount = totalAmount.toFixed(2);
      updatedRows[index] = updatedRow;

      // Update total amount
      const updatedTotal = updatedRows.reduce(
        (sum, row) => sum + parseFloat(row.amount),
        0
      );
      setEstimatePOData((prevData) => ({
        ...prevData,
        total: updatedTotal.toFixed(2),
      }));

      return updatedRows;
    });
  };

  // const handleTaxChange = (index, taxOption) => {
  //     setSelectedTax(taxOption);
  //     setRows(prevRows => {
  //         const updatedRows = [...prevRows];
  //         const updatedRow = { ...updatedRows[index], tax: taxOption };

  //         const rate = parseFloat(updatedRow.rate) || 0;
  //         const qty = parseFloat(updatedRow.qty) || 0;
  //         const taxPercentage = parseFloat(taxOption) || 0;

  //         const subtotal = rate * qty;
  //         const taxAmount = subtotal * (taxPercentage / 100);
  //         const totalAmount = subtotal + taxAmount;

  //         updatedRow.amount = totalAmount.toFixed(2);
  //         console.log(totalAmount)

  //         updatedRows[index] = updatedRow;
  //         return updatedRows;
  //     });
  // };

  // const calculateAmount = ({ qty, rate, tax }) => {
  //     const quantity = parseFloat(qty) || 0;
  //     const rateValue = parseFloat(rate) || 0;
  //     const taxRate = parseFloat(tax) || 0;
  //     return (quantity * rateValue * (1 + taxRate / 100)).toFixed(2);
  // };

  // const calculateTotal = () => {
  //     return rows.reduce((total, row) => total + parseFloat(row.amount || 0), 0).toFixed(2);
  // };

  useEffect(() => {
    const calculateAmounts = () => {
      let subTotal = 0;
      let totalTaxAmount = 0;

      rows.forEach((row) => {
        const rate = parseFloat(row.rate) || 0;
        const qty = parseFloat(row.qty) || 0;
        const taxPercentage = 9;

        const rowSubtotal = rate * qty;
        const taxAmount = rowSubtotal * (taxPercentage / 100);

        subTotal += rowSubtotal;
        totalTaxAmount += taxAmount;

        // Update the row amount
        row.amount = (rowSubtotal + taxAmount).toFixed(2);
      });

      setSubTotalAmount(subTotal.toFixed(2));
      setTotalTaxAmount(totalTaxAmount.toFixed(2));
    };

    calculateAmounts();
  }, [rows]);

  const handleDelete = async (index, id) => {
    const requestData = {
      id: id,
      rel_type: "estimate",
      rel_id: PurchaseID,
    };
    console.log(requestData);

    try {
      const response = await fetch(`${API_BASE_CRM_URL}DeleteItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
      setRows(newRows);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  //region update purchase order
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(rows, "rows when sebuit");

    const updatedFormValues = {
      ...formValues,
      products: rows,
      total: estimatePOData?.total,
      total_tax: totalTaxAmount,
      subtotal: subTotalAmount,
    };

    console.log(updatedFormValues, "data sending to api");

    try {
      const response = await fetch(`${API_BASE_CRM_URL}UpdatePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormValues),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result, "response from api");
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Purchase Order Updated Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/purchase-order");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Base>
      <div className="page-heade d-flex justify-content-between align-items-center mb-3">
        <div className="add-iem me-auto">
          <div className="">
            <h4>Edit Purchase Order</h4>
            {/* <h6 style={{ fontSize: '14px', color: '#495057', fontWeight: '400' }}>Modify fields for edit purchase order</h6> */}
          </div>
          <span className="fw-semibold">PO-{estimatePOData?.number}</span>{" "}
          {(() => {
            switch (estimatePOData?.status) {
              case 1:
                return (
                  <span className="badge fw-bold bg-outline-warning col-3">
                    Draft
                  </span>
                );
              case 2:
                return (
                  <span className="badge fw-bold bg-outline-success">Sent</span>
                );
              case 3:
                return (
                  <span className="badge fw-bold bg-outline-danger">
                    Declined
                  </span>
                );
              case 4:
                return (
                  <span className="badge fw-bold bg-outline-info">
                    Accepted
                  </span>
                );
              case 5:
                return (
                  <span className="badge fw-bold bg-outline-danger">
                    Expired
                  </span>
                );
              default:
                return (
                  <span className="badge fw-bold bg-outline-dark">Unknown</span>
                );
            }
          })()}
        </div>
        <div className="table-top-hea ms-auto">
          <Link to="/purchase-order">
            <button className="btn btn-primary">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </button>
          </Link>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Customer</label>
                <Select
                  showSearch
                  placeholder="Choose customer"
                  value={formValues.clientid || null}
                  onChange={handleCustomerChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={customerOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Project</label>
                <Select
                  showSearch
                  placeholder="Choose project"
                  value={formValues.project_id || null}
                  onChange={handleProjectChange}   
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={projectOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Status</label>
                <Select
                  showSearch
                  placeholder="Choose status"
                  value={selectedStatusid || null}
                  onChange={handleStatusChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={statusOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">purchaseOrder Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={formValues?.date}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Valid Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="expirydate"
                  value={formValues?.expirydate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Sale Agent</label>
                <Select
                  showSearch
                  placeholder="Choose agent"
                  value={formValues.sale_agent || null}
                  onChange={handleStaffChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={staffOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Product</label>
                <Select
                  showSearch
                  placeholder="Choose product"
                  onChange={handleProductChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={productOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div
                className="col-12 my-3 table-responsive"
                style={{ maxHeight: "20rem", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top">
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Tax</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product name"
                            value={row.description}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Quantity"
                            style={{ width: "6rem" }}
                            value={row.qty}
                            onChange={(e) =>
                              handleInputChange(index, "qty", e.target.value)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Rate"
                            style={{ width: "7.9rem" }}
                            value={row.rate}
                            onChange={(e) =>
                              handleInputChange(index, "rate", e.target.value)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </td>
                        <td>
                       
                          9.00% SGST
                        </td>
                        <td>{row.amount}</td>
                        <td>
                          <button
                            className="btn"
                            type="button"
                            onClick={() => handleDelete(index, row.id)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger fs-6"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12 my-3 text-end">
                <h5>Total Amount: {estimatePOData?.total}</h5>
              </div>
              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="clientnote"
                  value={formValues?.clientnote}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-md-2 col-lg-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
}
