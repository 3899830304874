import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";

export default function EditLogisticRate({ logisticRateObj }) {

    const [userDetails, SetUserDetails] = useState([]);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [logisticRate, setLogisticRate] = useState({
        Id: '',
        Name: '',
        Weight: '',
        Units: '',
        MinDistance: '',
        MaxDistance: '',
        Price: '',
        Notes: '',
        IsActive: '',
        UpdatedBy: '',
    }, [logisticRateObj]);

    useEffect(() => {
        setLogisticRate({
            Id: logisticRateObj?.id,
            Name: logisticRateObj?.name,
            Weight: logisticRateObj?.weight,
            Units: logisticRateObj?.units,
            MinDistance: logisticRateObj?.min_distance,
            MaxDistance: logisticRateObj?.max_distance,
            Price: logisticRateObj?.price,
            Notes: logisticRateObj?.notes,
            IsActive: logisticRateObj?.IsActive,
            UpdatedBy: userDetails?.staffid,
        });
    }, [logisticRateObj, userDetails]);

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLogisticRate((prevLogisticRate) => ({
            ...prevLogisticRate,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        setLoading(true);
        
        logisticRate.UpdatedBy = userDetails.staffid;
        console.log(logisticRate, 'data sending to api');
        
        fetch(`${API_BASE_CRM_URL}UpdateLogisticRate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(logisticRate)
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if (data.Status) {
                Swal.fire({
                    title: `Logistic Rate Updated Successfully.`,
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                });
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error("There was an error updating the Logistic Rate!", error);
            Swal.fire({
                title: "Error",
                text: "There was an error updating the Logistic Rate!",
                icon: "error",
            });
        });
    };      

    return (
        <div 
        className="offcanvas offcanvas-end custom-offcanvas"
        tabIndex="-1"
        id="offcanvasEdit"
        aria-labelledby="offcanvasRightLabel"
    >

        <style>
            {`
            .offcanvas.custom-offcanvas {
                width: 450px !important; /* Adjust this as needed */
            }

            @media (max-width: 576px) {
                .offcanvas.custom-offcanvas {
                width: 70% !important; /* Adjust the percentage as needed */
                }
            }
            `}
        </style>
        <div className="offcanvas-header">
                    <h4>Edit Logistic Rate</h4>
                    {logisticRate?.IsActive === 1 ? (
                        <span className="badge bg-outline-success">Active</span>
                    ) : (
                        <span className="badge bg-outline-danger">Inactive</span>
                    )}
                 <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body ms-2">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {/* <div className="mb-3 col-12">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="Name"
                                className="form-control"
                                placeholder="Name of Product"
                                value={logisticRate?.Name || ''}
                                onChange={handleChange}
                            />
                        </div> */}
                        <div className="mb-3 col-6">
                            <label className="form-label">Min Distance</label>
                            <input
                                type="text"
                                name="MinDistance"
                                className="form-control"
                                placeholder="Min Distance"
                                value={logisticRate?.MinDistance || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Max Distance</label>
                            <input
                                type="text"
                                name="MaxDistance"
                                className="form-control"
                                placeholder="Max Distance"
                                value={logisticRate?.MaxDistance || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Weight</label>
                            <input
                                type="text"
                                name="Weight"
                                className="form-control"
                                placeholder="Quantity"
                                value={logisticRate?.Weight || ''}
                                onChange={handleChange}
                            />
                        </div>
                        {/* <div className="mb-3 col-6">
                            <label className="form-label">Units</label>
                            <input
                                type="text"
                                name="Units"
                                className="form-control"
                                placeholder="Units"
                                value={logisticRate?.Units || ''}
                                onChange={handleChange}
                            />
                        </div> */}
                        <div className="mb-3 col-6">
                            <label className="form-label">Price</label>
                            <input
                                type="text"
                                name="Price"
                                className="form-control"
                                placeholder="Price"
                                value={logisticRate?.Price || ''}
                                onChange={handleChange}
                            />
                        </div>
                        
                        <div className="mb-3 col-12">
                            <label className="form-label">Notes</label>
                            <textarea
                                className="form-control"
                                name="Notes"
                                placeholder="Notes..."
                                value={logisticRate?.Notes || ''}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="m-auto col-5">
                        <button type="submit" className="btn btn-primary ">
                            {loading ? 'Submitting...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
                   
    );
}

EditLogisticRate.propTypes = {
    logisticRateObj: PropTypes.array.isRequired,
};
