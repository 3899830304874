
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


export default function ViewFollowUp({ folupObj }) {

    const [followupData, setFollowupData] = useState([]);

    useEffect(() => {
        setFollowupData(folupObj);
    }, [folupObj]);
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    };

    return (
        <div 
        className="offcanvas offcanvas-end custom-offcanvas"
        tabIndex="-1"
        id="offcanvasView"
        aria-labelledby="offcanvasRightLabel"
    >

        <style>
            {`
            .offcanvas.custom-offcanvas {
                width: 450px !important; /* Adjust this as needed */
            }

            @media (max-width: 576px) {
                .offcanvas.custom-offcanvas {
                width: 70% !important; /* Adjust the percentage as needed */
                }
            }
            `}
        </style>
        <div className="offcanvas-header">
                                <div className="page-title d-flex justify-content-between align-items-center">
                                    <h4>View FollowUp</h4>
                                    {followupData?.IsActive === 1 ? (
                                        <span className="badge bg-outline-success">Active</span>
                                    ) : (
                                        <span className="badge bg-outline-danger">Inactive</span>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    className="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="offcanvas-body ms-2">
                                <form>
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter title"
                                                value={followupData?.RelatedName}
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Title</label>
                                            <input
                                                type="text"
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter title"
                                                value={followupData?.Title}
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Type</label>
                                            <input
                                                type="text"
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter title"
                                                value={followupData?.relType}
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Follow By</label>
                                            <input
                                                type="text"
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter title"
                                                value={followupData?.FollowupType}
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Status</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={followupData?.FollowupStatus}
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Date</label>
                                            <input
                                                type="text"
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter title"
                                                value={formatDate(followupData?.Date)}
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-3 col-12">
                                            <label className="form-label">Notes</label>
                                            <textarea className="form-control" readOnly value={followupData?.Description || ''}></textarea>
                                        </div>
                                    </div>
                                    <div className="modal-footer-btn">
                                        <button type="button" className="btn btn-cancel me-2" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                   
    );
}

ViewFollowUp.propTypes = {
    folupObj: PropTypes.object.isRequired,
};
  