import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useLabelContext } from "../../../Context";

export default function EditChallan() {
  const [userDetails, SetUserDetails] = useState([]);
  //   const { ChallanID } = useParams();
  const { ID } = useLabelContext();
  const ChallanID = ID;
  let storedPurchaseId = sessionStorage.getItem("purchaseId");

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [challanData, setChallanData] = useState([]);
  const [selectedcontactid,setSelectedContactid]= useState("");
  const [loading, setLoading] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedProductid, setSelectedProductid] = useState("");
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [selectedprojectid, setSelectedProjectid] = useState("");
  const [projectLocation, setProjectLocation] = useState([]);
  const [formValues, setFormValues] = useState({
    deliveryGoodId: ChallanID,
    rel_type: "",
    customer_code: "",
    description: "",
    warehouse_id: "",
    staff_id: userDetails.staffid,
    date_c: "",
    addedfrom: userDetails.staffid,
    project: "",
    requester:selectedcontactid,
    vehicle_no: "",
    driver_name: "",
    site_latitude: "",
    site_longitude: "",
    warehouse_address: "",
    weight: "",
    distance: "",
    site_address: "",
    shipping_fee: "",
    cus_name: "",
    proj_name: "",
  });

  useEffect(() => {
    if (ChallanID) {
      sessionStorage.setItem("purchaseId", JSON.stringify(ChallanID));
    }

    let storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetChallanDetails?ID=${storedPurchaseId}&rem_type='requester-remainde'`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setLoading(false);
          setChallanData(data.ResultData);
          console.log(data.ResultData);
          console.log(data.ResultData.commodity_details);
          setFormValues({
            rel_type: data.ResultData.rel_type || "",
            customer_code: data.ResultData.customer_code || "",
            description: data.ResultData.description || "",
            date_c: formatDate(data.ResultData.date_c) || "",
            project: data.ResultData.project || "",
            vehicle_no: data.ResultData.vehicle_no || "",
            driver_name: data.ResultData.driver_name || "",
            site_latitude: data.ResultData.site_latitude || "",
            requester:data.ResultData.fullname || "",
            site_longitude: data.ResultData.site_longitude || "",
            weight: data.ResultData.weight || "",
            distance: data.ResultData.distance || "",
            shipping_fee: data.ResultData.shipping_fee || "",
            staff_id: data.ResultData.staff_id || "",
            addedfrom: data.ResultData.addedfrom || "",
            cus_name: data.ResultData.cus_name || "",
            proj_name: data.ResultData.proj_name || "",
            warehouse_id: data.ResultData.warehouse_id || "",
            warehouse_address: data.ResultData.warehouse_address || "",
          });
          console.log(data.ResultData.customer_code);
          setSelectedCustomerid(data.ResultData.customer_code || "");
          setSelectedProjectid(data.ResultData.project || "");
        } else {
          console.error("Fetched data is not an array:", data);
          setLoading(false);
          setChallanData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [ChallanID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setProductsData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setProductsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setCustomersData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setCustomersData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCustomerid) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            setProjectsData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedCustomerid]);

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleCustomerChange = (selectedOption) => {
    // setSelectedCustomerid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      customer_code: selectedOption,
      cus_name: selectedOption,
    }));
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProjectid(selectedOption);
    const label = projectsData.filter((item) => item.id === selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      project: selectedOption,
      proj_name: label,
    }));
  
  }

  const handleContactChange = (selectedOption) => {
    setSelectedContactid(selectedOption)
    const label = customerContactsData.filter((item) => item.id === selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      value: selectedOption,
      requester: selectedOption,
    }));
  
  }
  useEffect(()=>{



  const fetchLocationsData = async () => {
    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}GetLocationByProjectId?ProjID=${selectedprojectid}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.Status) {
        setProjectLocation(data.ResultData[0]);
        console.log(data.ResultData[0]);
      } else {
        console.error("Fetched data is not an array:", data);
        setProjectLocation([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchLocationsData();

},[selectedprojectid])




useEffect(() => {
     
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}GetCustomerContacts?CusId=${selectedCustomerid}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.Status) {
        setCustomerContactsData(data.ResultData);
        console.log(data.ResultData);
      } else {
        console.error("Fetched data is not an array:", data);
        setCustomerContactsData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [selectedCustomerid]);


const ContactOption = customerContactsData && customerContactsData?.map((item)=>({
  value: item.id,
  label: `${item.firstname} ${item.lastname}`
}))

  useEffect(()=>{

   
    const calculateDistance = async () => {
      const requestData = {
        ToLatitude: formValues.site_latitude,
        ToLongitude: formValues.site_longitude,
        FromLatitude: projectLocation.latitude,
        FromLongitude: projectLocation.longitude,
      };

      console.log(requestData, "data sending to api");

      try {
        const response = await fetch(
          `${API_BASE_CRM_URL}GetDistanceWithLatAndLong`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        // if (!response.ok) {
        //   throw new Error("Failed to calculate distance");
        // }
        const data = await response.json();
        console.log(data);
        formValues.distance = data.distance;
      } catch (err) {
        // setError('Failed to calculate distance. Please try again.');
        console.error("Error:", err);
      }
    };

    calculateDistance();
  })


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCommodityChange = (e, index) => {
    const { name, value } = e.target;
    const updatedCommodity = [...challanData.commodity_details];
    updatedCommodity[index] = {
      ...updatedCommodity[index],
      [name]: value,
    };
    setChallanData((prevState) => ({
      ...prevState,
      commodity_details: updatedCommodity,
    }));
  };

  const handleProductChange = (selectedOption) => {
    setSelectedProductid(selectedOption);
  };

  const customerOptions =
    customersData &&
    customersData.map((item) => ({
      value: item.userid,
      label: item.company,
    }));

  const projectOptions =
    projectsData &&
    projectsData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const productOptions =
    productsData &&
    productsData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const handlesubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);
    console.log(storedPurchaseId);
    const requestData = {
      deliveryGoodId: storedPurchaseId,
      rel_type: formValues.rel_type || 0,
      customer_code: formValues.customer_code,
      description: formValues.description,
      // warehouse_id: formValues.warehouse_id,
      staff_id: formValues.staff_id,
      date_c: formValues.date_c,
      requester:selectedcontactid,
      addedfrom: formValues.addedfrom,
      project: formValues.project,
      vehicle_no: formValues.vehicle_no,
      driver_name: formValues.driver_name,
      site_latitude: formValues.site_latitude,
      site_longitude: formValues.site_longitude,
      warehouse_address: formValues.warehouse_address,
      weight: formValues.weight,
      distance: formValues.distance,
      site_address: formValues.site_address,
      shipping_fee: formValues.shipping_fee,
      commodity_details: challanData.commodity_details,
    };
    console.log(requestData);
    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}UpdateChallanWithDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Purchase Order Added Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            // window.location.reload();
            // navigate("/delivery-challan");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  console.log(challanData.commodity_details);

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>Edit Delivery Chalan</h4>
            <h6>Modify fields for edit challan</h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/delivery-challan" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handlesubmit}>
            <div className="row m-2 mt-3">
              <div className="d-flex flex-column col-md-4 col-lg-4">
                <label className="form-label">Customer</label>
                <Select
                  showSearch
                  placeholder="Choose Customer"
                  value={formValues.cus_name || null}
                  // onChange={handleCustomerChange}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? "")
                  //     .toLowerCase()
                  //     .includes(input.toLowerCase())
                  // }
                  // options={customerOptions}
                  // style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-md-4 col-lg-4">
                <label className="form-label">Project</label>
                <Select
                  showSearch
                  placeholder="Choose Project"
                  value={formValues.proj_name || null}
                  onChange={handleProjectChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={projectOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Chalan Date</label>
                <input
                  type="date"
                  name="date_c"
                  className="form-control"
                  value={formValues.date_c}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Site Latitude</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter site latitude"
                  name="site_latitude"
                  value={formValues.site_latitude}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Site Longitude</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter site longitude"
                  name="site_longitude"
                  value={formValues?.site_longitude}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Distance</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Site distance will display here"
                  value={formValues?.distance}
                  // onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Vehicle No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter vehicle no"
                  name="vehicle_no"
                  value={formValues?.vehicle_no}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Delivery Person</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter driver name"
                  name="driver_name"
                  value={formValues?.driver_name || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-md-4 col-lg-4">
                <label className="form-label">Requester</label>
                <Select
                  showSearch
                  placeholder="Choose Project"
                  value={formValues.requester || null}
                  onChange={handleContactChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={ContactOption}
                  style={{ height: "2.4rem" }}
                />
              </div>
              {/* <div className="d-flex flex-column col-md-4 col-lg-4">
                <label className="form-label">Product</label>
                <Select
                  showSearch
                  placeholder="Choose Product"
                  value={selectedProductid || null}
                  onChange={handleProductChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={productOptions}
                  style={{ height: "2.4rem" }}
                />
              </div> */}
              <div className="col-12 my-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Weight</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {challanData.commodity_details?.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product name"
                            value={row.commodity_name}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Quantity"
                            name="quantities"
                            value={row.quantitities}
                            onChange={(e) => handleCommodityChange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Weight"
                            value={formValues?.weight}
                             onChange={(e) => handleCommodityChange(e, index)}
                          />
                        </td>
                        <td>
                          <button className="btn">
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger fs-6"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12 my-3 text-end">
                                <h5>Shipping Fee:{formValues?.shipping_fee} </h5>
                            </div>
              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter notes..."
                  value={formValues?.description}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-column col-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
}
