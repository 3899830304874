import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import "../../Config/search.css";
import "../../Config/loader.css";
import "../../Config/loading.css";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import {
    faTrashCan,
    faGear,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Option } = Select;

export default function PostInventory() {
    const navigate = useNavigate();

    useEffect(() => {
        const userDetails = sessionStorage.getItem("userDetails");
        if (!userDetails) {
            navigate("/");
        }
    }, [navigate]);

    const [excloading, setExcLoading] = useState(false);
    const [excelData, setExcelData] = useState([]);

    const [tableData, setTableData] = useState(
        Array.from({ length: 10 }, () => ({
            warehouseId: "",
            commodityId: "",
            lotNumber: "",
            expiryDate: "",
            inventory: ""
        }))
    );


    const [loading, setLoading] = useState(false);
    const [warehousesData, setWarehousesData] = useState([]);
    const [productsData, setProductsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProductsData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const getWarehouses = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetWarehouse`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setWarehousesData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setWarehousesData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getWarehouses();
    }, []);

    const warwhouseOptions =
        warehousesData &&
        warehousesData.map((item) => ({
            value: item.warehouse_id,
            label: item.warehouse_name,
        }));

    const productOptions =
        productsData &&
        productsData.map((item) => ({
            value: item.id,
            label: item.description,
            rate: parseFloat(item.rate),
        }));

    const handleChange = (index, value, name) => {
        const newTableData = [...tableData];
        newTableData[index][name] = value;
        setTableData(newTableData);
    };
    
    const handleAddRow = () => {
        setTableData((prevData) => [
            ...prevData,
            { warehouseId: "", commodityId: "", lotNumber: "", expiryDate: "", inventory: "" }
        ]);
        console.log("Added new row. Updated tableData:", tableData);
    };




    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        setLoading(true); // Set loading state to true during submission

        // Log the original tableData for debugging purposes
        console.log("Original tableData before filtering:", tableData);

        // Filter rows that are completely filled (i.e., all required fields have values)
        const validData = tableData.filter(row =>
            row.commodityId && row.warehouseId && row.lotNumber && row.expiryDate && row.inventory
        ).map(row => ({
            warehouse_name: row.warehouseId,
            commodity_name: row.commodityId,
            inventory_number: row.inventory,
            expiry_date: row.expiryDate,
            lot_number: row.lotNumber,
        }));

        // Log the filtered valid data for debugging purposes
        console.log("Filtered valid data to send:", validData);

        // Check if there is no valid data to submit
        if (validData.length === 0) {
            alert("No valid rows to submit. Please fill out all required fields.");
            setLoading(false);
            return; // Exit the function if no rows are valid
        }

        try {
            // Submit each valid row to the server
            for (const [i, data] of validData.entries()) {
                const response = await fetch(`${API_BASE_CRM_URL}InventoryManage`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data), // Send one valid row at a time
                });

                if (!response.ok) {
                    throw new Error(`Error submitting row ${i + 1}: ${response.statusText}`);
                }

                const result = await response.json();
                console.log(`Success submitting row ${i + 1}:`, result);
            }

            alert("Data submitted successfully!"); // Notify the user of successful submission
        } catch (error) {
            console.error("Error during submission:", error);
            alert("There was an error submitting the data. Please try again.");
        }

        setLoading(false); // Set loading state back to false after submission
    };

    const excdata = [
        [
            "Warehouse Name",
            "Commodity Name",
            "Lot Number",
            "Expiry Date",
            "Inventory Number",
        ],
    ];

    const GenerateDownloadExcel = (e) => {
        const ws = XLSX.utils.aoa_to_sheet(excdata);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Student Data");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        saveAs(blob, "inventory.xlsx");
    };


    const baseDate = new Date(1900, 0, 0);
    const warehouseToId = warwhouseOptions.reduce((acc, item) => {
        acc[item.label] = item.value;
        return acc;
    }, {});

    const commodityToId = productOptions.reduce((acc, item) => {
        acc[item.label] = item.value;
        return acc;
    }, {});

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (
            file &&
            (file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type === "application/vnd.ms-excel")
        ) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });
                console.log(jsonData);
                const mappedWarehouse = jsonData.map((row) => {
                    const label = row["Warehouse Name"].trim();
                    return {
                        label,
                        id: warehouseToId[label] || null,
                    };
                });

                const mappedCommodity = jsonData.map((row) => {
                    const label = row["Commodity Name"].trim();
                    return {
                        label,
                        id: commodityToId[label] || null,
                    };
                });
                console.log(mappedCommodity);
                const formatDateToDDMMYYYY = (dateString) => {
                    const [year, month, day] = dateString.split("-").map(Number);
                    return `${year}-${month.toString().padStart(2, "0")}-${day
                        .toString()
                        .padStart(2, "0")}`;
                };

                const formattedData = jsonData.map((item) => {
                    const serialDate =
                        item["Expiry Date"] === undefined ? "" : item["Expiry Date"];
                    const date = new Date(
                        baseDate.getTime() + serialDate * 24 * 60 * 60 * 1000
                    );

                    const isoDate = date.toISOString().split("T")[0];
                    const formattedDate = formatDateToDDMMYYYY(isoDate);
                    const warehouseName = item["Warehouse Name"]
                        ? item["Warehouse Name"].trim()
                        : "";
                    const warehouseEntry = mappedWarehouse.find(
                        (result) => result.label === warehouseName
                    );
                    const warehouseId = warehouseEntry ? warehouseEntry.id : null;

                    const commodityName = item["Commodity Name"]
                        ? item["Commodity Name"].trim()
                        : "";
                    const commodityEntry = mappedCommodity.find(
                        (result) => result.label === commodityName
                    );
                    const commodityId = commodityEntry ? commodityEntry.id : null;

                    return {
                        warehouse_name: warehouseId,
                        commodity_name: commodityId,
                        lot_number: item["Lot Number"] ? item["Lot Number"] : "",
                        expiry_date: formattedDate,
                        inventory_number: item["Inventory Number"]
                            ? item["Inventory Number"]
                            : "",
                    };
                });


                const chunkSize = 50;
                if (formattedData.length > chunkSize) {
                    alert(`Please enter not more than ${chunkSize} records.`);
                    return;
                }

                const totalChunks = 1;
                let accumulatedData = [];

                const processChunk = async (chunkIndex) => {
                    if (chunkIndex >= totalChunks) {

                        setExcelData(accumulatedData);
                        return;
                    }

                    const startIndex = chunkIndex * chunkSize;
                    const endIndex = Math.min(
                        startIndex + chunkSize,
                        formattedData.length
                    );
                    const chunk = formattedData.slice(startIndex, endIndex);

                    accumulatedData = accumulatedData.concat(chunk);



                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    processChunk(chunkIndex + 1);
                };

                processChunk(0);
            };
            reader.readAsArrayBuffer(file);
        } else {
            alert("Please select a valid Excel file.");
        }
    };
    const handleUploadExcel = async (e) => {
        e.preventDefault();
        console.log(excelData);

        if (excelData.length === 0) return;

        let successfulRecords = 0;
        let errorOccurred = false;
        setExcLoading(true);

        try {
            for (let i = 0; i < excelData.length; i++) {
                const item = excelData[i];
                console.log(item);

                try {
                    const response = await fetch(`${API_BASE_CRM_URL}inventorymanage`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            lot_number: item.lot_number,
                            expiry_date: item.expiry_date,
                            inventory_number: item.inventory_number,
                            warehouse_name: item.warehouse_name,
                            commodity_name: item.commodity_name,
                        }),
                    });

                    const responseText = await response.json();
                    console.log("Response Text:", responseText);

                    if (responseText.Status === false) {

                        Swal.fire({
                            icon: "warning",
                            title: "Warning!",
                            text: `Record in row ${i + 2} failed to upload.`,
                            confirmButtonText: "Continue",
                        });

                    } else {
                        successfulRecords += 1;
                    }
                } catch (innerError) {

                    Swal.fire({
                        icon: "error",
                        title: "Oops!",
                        text: "Something went wrong with the request. Please try again.",
                        confirmButtonText: "Retry",
                    });

                }
            }

            if (!errorOccurred && successfulRecords > 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "All records processed successfully.",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {

            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Something went wrong. Please try again.",
                confirmButtonText: "Retry",
            });
        } finally {
            setExcelData([]);
        }
    };

    return (
        <Base>
            <div className="page-header d-flex justify-content-between align-items-center mb-3 flex-wrap">
                {/* Left Section: Add Inventory Heading */}
                <div className="d-flex flex-column me-auto">
                    <h4>Add Inventory</h4>
                    <h6 style={{ fontSize: "14px", color: "#495057", fontWeight: "400" }}>
                        Enter fields for add Inventory
                    </h6>
                </div>

                {/* Right Section: Buttons for Upload Excel and Go to List */}
                <div className="d-flex ms-auto align-items-center">
                    <button
                        className="btn btn-primary mx-2"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasAdd"
                        aria-controls="offcanvasAdd"
                        title="Upload Excel"
                    >
                        <i className="fa-solid fa-plus"></i>
                    </button>
                    <Link to="/inventory">
                        <button className="btn btn-primary">
                            <i className="fa-solid fa-arrow-left me-1"></i>Go to List
                        </button>
                    </Link>
                </div>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-responsive" style={{ minHeight: "24rem" }}>
                        <form onSubmit={handleSubmit}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Commodity Name </th>
                                        <th>Warehouse Name</th>
                                        <th>Lot Number</th>
                                        <th>Expiry Date</th>
                                        <th>Inventory</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((row, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Select
                                                    name="commodityId"
                                                    value={row.commodityId}
                                                    onChange={(value) => handleChange(index, value, 'commodityId')}
                                                    style={{ width: 200 }}
                                                    placeholder="Select Commodity"
                                                >
                                                    <Option value="" disabled>
                                                        Select Commodity
                                                    </Option>
                                                    {productsData.length > 0 ? (
                                                        productsData.map((productsdata) => (
                                                            <Option key={productsdata.id} value={productsdata.id}>
                                                                {productsdata.description}
                                                            </Option>
                                                        ))
                                                    ) : (
                                                        <Option disabled>Loading Commodities...</Option>
                                                    )}
                                                </Select>
                                            </td>

                                            <td>
                                                <Select
                                                    name="warehouseId"
                                                    placeholder="Select Warehouse"
                                                    value={row.warehouseId}
                                                    onChange={(value) => handleChange(index, value, 'warehouseId')} // Update state correctly
                                                    style={{ width: 200 }}


                                                >
                                                    <Option value="" disabled>
                                                        Select Warehouse
                                                    </Option>
                                                    {warehousesData.length > 0 ? (
                                                        warehousesData.map((warehousesdata) => (
                                                            <Option key={warehousesdata.warehouse_id} value={warehousesdata.warehouse_id}>
                                                                {warehousesdata.warehouse_name}
                                                            </Option>
                                                        ))
                                                    ) : (
                                                        <Option disabled>Loading Warehouses...</Option>
                                                    )}
                                                </Select>

                                            </td>
                                            <td>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="lotNumber"
                                                    value={row.lotNumber}
                                                    onChange={(e) => handleChange(index, e.target.value, 'lotNumber')}
                                                    placeholder="Enter lot number"

                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    name="expiryDate"
                                                    value={row.expiryDate}
                                                    onChange={(e) => handleChange(index, e.target.value, 'expiryDate')}

                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    style={{ width: "10rem" }}
                                                    value={row.inventory}
                                                    onChange={(e) => handleChange(index, e.target.value, 'inventory')}
                                                    placeholder="Enter inventory"
                                                    onWheel={(e) => e.target.blur()}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <br />

                            <div className="text-center m-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary col-3"
                                    disabled={loading}
                                >
                                    {loading ? "Adding..." : "Add inventory"}
                                </button>
                            </div>
                        </form>

                        <div className="text-center m-auto">
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-primary col-3"
                                    onClick={handleAddRow}
                                >
                                    Add +
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="offcanvas offcanvas-end custom-offcanvas"
                        tabIndex="-1"
                        id="offcanvasAdd"
                        aria-labelledby="offcanvasRightLabel"
                    >
                        <style>
                            {`
                        .offcanvas.custom-offcanvas {
                            width: 450px !important; /* Adjust this as needed */
                        }

                        @media (max-width: 576px) {
                            .offcanvas.custom-offcanvas {
                            width: 70% !important; /* Adjust the percentage as needed */
                            }
                        }
                        `}
                        </style>
                        <div className="offcanvas-header">
                            <h4>Upload Excel</h4>
                            <FontAwesomeIcon
                                icon={faDownload}
                                className="mx-2 text-success"
                                onClick={GenerateDownloadExcel}
                            />
                            <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                            {/* <p className='fw-semibold'>Fill out the form to add a new product</p> */}
                        </div>
                        <div className="offcanvas-body ms-2">
                            <form onSubmit={handleUploadExcel}>
                                <div className="mb-2">
                                    <label className="form-label">
                                        File<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        accept=".xlsx, .xls"
                                        // value={formValues.name}
                                        onChange={handleFileChange}
                                        required
                                    />
                                </div>

                                <div className="text-center m-auto">
                                    <button
                                        type="submit"
                                        className="btn btn-primary col-7"
                                        disabled={loading}
                                    >
                                        {loading ? "Adding..." : "Add Product"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    );
}
