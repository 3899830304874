import React, { createContext, useState, useContext } from "react";

// Create a context
const ContextPage = createContext();

// Create a provider component
export const ContextProvider = ({ children }) => {
  const [ID, setID] = useState(null);

  return (
    <ContextPage.Provider value={{ ID, setID }}>
      {children}
    </ContextPage.Provider>
  );
};


export const useLabelContext = () => {
  return useContext(ContextPage);
};
