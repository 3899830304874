import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faGear } from "@fortawesome/free-solid-svg-icons";

export default function InternalDeliveryNote() {
  const [userDetails, SetUserDetails] = useState([]);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedprojectid, setSelectedProjectid] = useState("");
  const [selectedStatusid, setSelectedStatusid] = useState("");
  const [selectedStaffid, setSelectedStaffid] = useState("");
  const [selectedItemid, setSelectedItemid] = useState("");
  const [selectedFSNid, setSelectedFSNid] = useState("");
  const [selectedTSNid, setSelectedTSNid] = useState("");

  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);
  const [warehouesData, setWarehouseData] = useState([]);
  const [availqa,SetAvailQa] = useState ([]);

  const [description, setDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [deliveryNote, SetDeliveryNote] = useState({
    note_name: "",
    accounting_date: "",
    voucher_date: "",
    note_number: "",
    deliverer: "",
    description: "",
    items: [],
  });

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const data = await response.json();
        if (data.Status) {
          setData(data.ResultData);
        } else {
          console.error(`Fetched data from ${url} is not an array:`, data);
          setData([]);
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData(`${API_BASE_CRM_URL}GetCustomers`, setCustomersData);
    fetchData(`${API_BASE_CRM_URL}GetStaff`, setStaffData);
    fetchData(`${API_BASE_CRM_URL}GetTaxes`, setTaxesData);
    fetchData(`${API_BASE_CRM_URL}GetProducts`, setProductsData);
  }, []);

  useEffect(() => {
    if (selectedItemid && selectedFSNid) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}Getqtybywhid?whid=${selectedFSNid}&prid=${selectedItemid}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            SetAvailQa(data.ResultData);
            SetDeliveryNote((prev) => ({
              ...prev,
              items: prev.items.map((item, index) => ({
                ...item,
                available_quantity: data.ResultData[0]?.quantity, 
              })),
            }));
            console.log(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            SetAvailQa([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedItemid,selectedFSNid]);



  const customerOptions =
    customersData &&
    customersData.map((item) => ({
      value: item.userid,
      label: item.company,
    }));

  const projectOptions =
    projectsData &&
    projectsData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const productOptions =
    productsData &&
    productsData.map((item) => ({
      value: item.id,
      label: item.description,
      rate: parseFloat(item.rate),
    }));

  const staffOptions =
    staffData &&
    staffData.map((item) => ({
      value: item.staffid,
      label: item.firstname + " " + item.lastname,
    }));

  const taxOptions =
    taxesData &&
    taxesData.map((item) => ({
      key: item.id,
      value: item.taxrate,
      label: item.name + " " + item.taxrate,
    }));

  const warehouseOptions =
    warehouesData &&
    warehouesData.map((item) => ({
      value: item.warehouse_id,
      label: item.warehouse_name,
    }));

  const statusOptions = [
    { value: 1, label: "Draft" },
    { value: 2, label: "Sent" },
    { value: 5, label: "Expired" },
    { value: 3, label: "Declined" },
    { value: 4, label: "Accepted" },
  ];

  const handleCustomerChange = (selectedone) => {
    setSelectedCustomerid(selectedone);
  };

  const handleProjectChange = (selectedone) => {
    setSelectedProjectid(selectedone);
  };

  const handleStatusChange = (selectedone) => {
    setSelectedStatusid(selectedone);
  };

  
  const handleTOstockChange = (selectedone) => {
    setSelectedTSNid(selectedone);
  };

  const handleStaffChange = (selectedone) => {
    setSelectedStaffid(selectedone);
  };

  const handleEstimateDateChange = (event) => {
    setEstimateDate(event.target.value);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };
 

  const addRow = (product) => {
    if (rows.some((row) => row.description === product.label)) {
      Swal.fire({
        title: "Product already added",
        text: "This product is already in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const newRow = {
      description: product.label,
      long_description: product.value || "",
      qty: 1,
      rate: product.rate || 0,
      tax: 9, // Fixed tax rate 9%
      amount: 0,
      item_order: rows.length + 1,
    };

    // Calculate initial amount
    const subtotal = parseFloat(newRow.rate) * parseFloat(newRow.qty);
    const taxAmount = subtotal * (9 / 100);
    newRow.amount = (subtotal + taxAmount).toFixed(2);

    const updatedRows = [...rows, newRow];

    setRows(updatedRows);
  };
  const grandTotal = deliveryNote.items.reduce(
    (acc, row) => acc + row.quantity * row.unit_price,
    0
  );

  const handleProductChange = (selectedone, index) => {
    setSelectedItemid(selectedone);
    console.log(selectedone);
    const status = productOptions.find(
      (option) => option.value === selectedone
    );
    let result = status ? status.label : "";
    SetDeliveryNote({
      ...deliveryNote,
      items: [
        ...deliveryNote.items,
        {
          id: selectedone,
          item: result,
          item_code: selectedone,
          from_stock_name: "",
          to_stock_name: "",
          quantity: "",
          available_quantity: "",
          unit_price: "",
          amount: "",
        },
      ],
    });
    console.log(deliveryNote);
  };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...deliveryNote.items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value,
    };

    if (field === "quantity" || field === "unit_price") {
      const quantity = parseFloat(updatedItems[index].quantity) || 0; 
      const unitPrice = parseFloat(updatedItems[index].unit_price) || 0; 

      updatedItems[index].amount = parseInt(quantity) * parseInt(unitPrice); 
    }

    SetDeliveryNote({ ...deliveryNote, items: updatedItems });
  };

  const handleAvailableQuantityChange = (value) => {
    const updatedAvailqa = [...availqa];
    updatedAvailqa[0].quantity = value;

    SetAvailQa(updatedAvailqa); 
    console.log(availqa);
  
    // SetDeliveryNote((prev) => ({
    //   ...prev,
    //   items: prev.items.map((item, index) => ({
    //     ...item,
    //     available_quantity: value, 
    //   })),
    // }));
  };
  

  const handleFromstockChange = (selectedone) => {
    setSelectedFSNid(selectedone);
    console.log(selectedone);

    SetDeliveryNote((prev) => ({
      ...prev,
      items: prev.items.map((item, index) => ({
        ...item,
        available_quantity: availqa[0]?.quantity, 
      })),
    }));
  
    
    const selectedWarehouse = warehouseOptions.find(option => option.value === selectedone);

    console.log(selectedWarehouse);
    if (selectedWarehouse) {
      SetDeliveryNote((prev) => ({
        ...prev,
        items: prev.items.map((item, index) => ({
          ...item,
          from_stock_name: selectedWarehouse.label, // Update 'from_stock_name' for each item
        })),
      }));
          
      // SetDeliveryNote({
      //   ...deliveryNote,
      //   items: [
      //     ...deliveryNote.items,
      //     {      
      //       from_stock_name: selectedWarehouse.label, 
      //     },
      //   ],

      // });
    }
  };
  
   

  useEffect(() => {
    const calculateAmounts = () => {
      let subTotal = 0;
      let totalTaxAmount = 0;
      let totalAmount = 0;

      rows.forEach((row) => {
        const rate = parseFloat(row.rate) || 0;
        const qty = parseFloat(row.qty) || 0;
        const taxPercentage = 9;

        const rowSubtotal = rate * qty;
        const taxAmount = rowSubtotal * (taxPercentage / 100);

        subTotal += rowSubtotal;
        totalTaxAmount += taxAmount;

        totalAmount += subTotal + totalTaxAmount;

        // Update the row amount
        row.amount = (rowSubtotal + taxAmount).toFixed(2);
      });

      setSubTotalAmount(subTotal.toFixed(2));
      setTotalAmount(totalAmount.toFixed(2));
      setTotalTaxAmount(totalTaxAmount.toFixed(2));
    };

    const getWarehouse = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_CRM_URL}GetWarehouse`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.Status) {
          setWarehouseData(data.ResultData);
        } else {
          console.error("Fetched data is not an array:", data);
          setWarehouseData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getWarehouse();

    calculateAmounts();
  }, [rows]);

  const handleDelete = async (index) => {
    console.log(index);
    const newRows = deliveryNote.items.filter((item) => item.id !== index);
    SetDeliveryNote({ ...deliveryNote, items: newRows });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    console.log(deliveryNote);
    const internaldeliverydata = {
      note_name: deliveryNote.note_name,
      accounting_date: deliveryNote.accounting_date,
      total_amount: grandTotal,
      voucher_date: deliveryNote.voucher_date,
      note_number: deliveryNote.note_number,
      staffid: selectedStaffid,
      deliverer: deliveryNote.deliverer,
      description: deliveryNote.description,
      items: deliveryNote.items,
    };

    // const totalAmount = calculateTotal();

    console.log(internaldeliverydata, "data sending to api");

    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}inventory/internaldeliverynote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(internaldeliverydata),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result, "rrrrrrrrrrrrrrrrrrr");
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Reminder Set Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            // window.location.reload();
            // navigate("/purchase-order");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleDeliveryNote = (e) => {
    const { name, value } = e.target;
    SetDeliveryNote((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Base>
      <div className="page-heade d-flex justify-content-between align-items-center mb-3">
        <div className="add-iem me-auto">
          <div className="">
            <h4>Internal Delivery Note</h4>
            <h6
              style={{ fontSize: "14px", color: "#495057", fontWeight: "400" }}
            >
              Enter fields for Delivery Note
            </h6>
          </div>
        </div>
        <div className="table-top-hea ms-auto">
          <Link to="/inventory">
            <button className="btn btn-primary">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </button>
          </Link>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
              <div className="col-md-4 col-lg-4 mb-2">
                <label className="form-label">Delivery note name<span className='text-danger'>*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="note_name"
                  placeholder="Delivery Note"
                  value={deliveryNote.note_name || ""}
                  onChange={handleDeliveryNote}
                  required
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Accounting Date <span className='text-danger'>*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="accounting_date"
                  value={deliveryNote.accounting_date || ""}
                  onChange={handleDeliveryNote}
                  required
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Voucher date</label>
                <input
                  type="date"
                  className="form-control"
                  name="voucher_date"
                  value={deliveryNote.voucher_date || ""}
                  onChange={handleDeliveryNote}
                />
              </div>

              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Delivery note number</label>
                <input
                  type="number"
                  className="form-control"
                  name="note_number"
                  value={deliveryNote.note_number || ""}
                  onChange={handleDeliveryNote}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">deliverer<span className='text-danger'>*</span></label>
                <Select
                  showSearch
                  placeholder="Choose agent"
                  value={selectedStaffid || null}
                  onChange={handleStaffChange}
                  required
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={staffOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>

              <div className="d-flex flex-column col-lg-12 col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="description"
                  value={deliveryNote.description || ""}
                  onChange={handleDeliveryNote}
                  placeholder="Enter notes..."
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Item</label>
                <Select
                  showSearch
                  placeholder="Select Item"
                  value={selectedItemid || null}
                  onChange={handleProductChange}
                  filterOption={(input, option) =>
                    (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={productOptions}
                  style={{ height: "2.4rem" }}
                />
              </div>

              <div
                className="col-12 my-3 table-responsive"
                style={{ maxHeight: "20rem", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top">
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>From stock name</th>
                      <th>To stock name </th>
                      <th>Available Quantity</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Amount</th>

                      <th>
                        <FontAwesomeIcon
                          icon={faGear}
                          className="text-primary fs-6"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryNote.items &&
                      deliveryNote.items.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Product name"
                              style={{ width: "8rem" }}
                              value={row.item}
                              readOnly
                            />
                          </td>
                          <td>
                            <Select
                              showSearch
                              placeholder="From stock"
                              value={selectedFSNid || null}
                              onChange={handleFromstockChange}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={warehouseOptions}
                              style={{ height: "2.4rem", width: "8rem" }}
                             
                            />
                          </td>
                          <td>
                            <Select
                              showSearch
                              placeholder="To stock"
                              value={row.to_stock_name || null}
                              // onChange={handleTOstockChange}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={warehouseOptions}
                              style={{ height: "2.4rem", width: "8rem" }}
                              onChange={(value, option) =>
                                handleInputChange(
                                  index,
                                  "to_stock_name",
                                  option.label
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Available quantity"
                              style={{ width: "7rem" }}
                              value={availqa[0]?.quantity}
                              onChange={(e) => {
                                // Update the quantity in availqa state
                                handleAvailableQuantityChange(e.target.value);
                              }}
                              onWheel={(e) => e.target.blur()}
                            />
                          </td>
                          <td>
                            {" "}
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Quantity"
                              style={{ width: "7rem" }}
                              value={row.quantity}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "quantity",
                                  Number(e.target.value) || 0
                                )
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Unit Price"
                              style={{ width: "7rem" }}
                              value={row.unit_price}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "unit_price",
                                  Number(e.target.value) || 0
                                )
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </td>
                          <td>{row.quantity * row.unit_price}</td>
                          <td>
                            <button
                              type="button"
                              className="btn"
                              onClick={() => handleDelete(row.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger fs-6"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12 my-3 text-end">
                <h5>Total Amount: {grandTotal}</h5>
              </div>

              <div className="d-flex flex-column col-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
}
