
import React from "react";

export default function EditReminder() {
    return (
        <div className="modal fade" id="edit-reminder">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
                <div className="modal-content">
                    <div className="page-wrapper-new p-0">
                        <div className="content">
                            <div className="modal-header border-0 custom-modal-header">
                                <div className="page-title d-flex">
                                    <h4>Edit Reminder</h4>
                                    <p className="badge bg-outline-success">Active</p>
                                </div>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body custom-modal-body new-employee-field">
                                <form action="brand-list.html">
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Customer Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Organization</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Phone</label>
                                            <input type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Source</label>
                                            <input type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Date</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Notify</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Last Contact</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="modal-footer-btn">
                                        <button type="button" className="btn btn-cancel me-2" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}