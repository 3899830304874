import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";

export default function SetReminder({ leadOBJ }) {

    
    const [userDetails, SetUserDetails] = useState([]);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [loading, setLoading] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [remindTime, setRemindTime] = useState('');

    const initialFormData = {
        relId: leadOBJ?.id || "",
        reminderType: "Lead",
        staffId: userDetails.staffid,
        hours: 0,
        days: 0,
        months: 0,
        description: ""
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        if (leadOBJ) {
            setFormData((prevData) => ({
                ...prevData,
                relId: leadOBJ.id || prevData.relId
            }));
        }
    }, [leadOBJ]);

    const handleSubmit = async () => {
        if (!remindTime) {
            alert('Please select remind time.');
            return;
        }

        formData.staffId = userDetails.staffid;
        console.log(formData, 'data sending to api')
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_CRM_URL}AddReminder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });
            
            const result = await response.json();
            console.log(result, 'rrre');
            
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Reminder Set Successfully.`,
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
    };

    const handleCheckboxChange = (value) => {
        setSelectedCheckbox((prevValue) => (prevValue === value ? "" : value));
    };

    const handleChange1 = (event) => {
        const value = event.target.value;
        setRemindTime(value);

        if (!value) {
          setErrorMessage('');
        } else if (!/^[1-8]$/.test(value)) {
          setErrorMessage('Value must be between 1 and 8');
        } else {
          setErrorMessage('');
        }

        // Reset all to "" if no checkbox is selected
        setFormData(prevData => ({
            ...prevData,
            hours: 0,
            days: 0,
            months: 0,
        }));

        if (selectedCheckbox === 'hours'){
          setFormData(prevData => ({
              ...prevData,
              hours: parseInt(value)
          }));
        } else if (selectedCheckbox === 'days'){
          setFormData(prevData => ({
              ...prevData,
              days: parseInt(value)
          }));
        } else if (selectedCheckbox === 'months'){
          setFormData(prevData => ({
              ...prevData,
              months: parseInt(value)
          }));
        }
    };

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        setFormData(prevData => ({
            ...prevData,
            description: value
        }));
    };

    const handleCancel = () => {
        setFormData(initialFormData);
        setSelectedCheckbox("");
        setRemindTime("");
        setErrorMessage("");
    };

    return (
        <div 
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasReminder"
            aria-labelledby="offcanvasRightLabel"
        >

        <style>
            {`
            .offcanvas.custom-offcanvas {
                width: 450px !important; /* Adjust this as needed */
            }

            @media (max-width: 576px) {
                .offcanvas.custom-offcanvas {
                width: 70% !important; /* Adjust the percentage as needed */
                }
            }
            `}
        </style>
        <div className="offcanvas-header">
            <h4>Set Reminder</h4>
            <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div className="offcanvas-body ms-2">
            <form>
                <div className="row">
                    <div className="col-md-6 col-lg-6">
                        <h6>{leadOBJ.name && leadOBJ.name}</h6>
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                        <h6>{leadOBJ.company && leadOBJ.company}</h6>
                    </div>
                    <div className="d-flex align-items-center">
                        {/* <p className="me-3 mb-0">Time Period:<span className="fw-bold text-danger ms-1">*</span></p> */}
                        <div className="form-group d-flex align-items-center me-3">
                            <label className="form-label me-1" htmlFor="Hours">Hours</label>
                            <input
                                type="checkbox"
                                id="Hours"
                                checked={selectedCheckbox === "hours"}
                                onChange={() => handleCheckboxChange("hours")}
                            />
                        </div>
                        <div className="form-group d-flex align-items-center me-3">
                            <label className="form-label me-1" htmlFor="Days">Days</label>
                            <input
                                type="checkbox"
                                id="Days"
                                checked={selectedCheckbox === "days"}
                                onChange={() => handleCheckboxChange("days")}
                            />
                        </div>
                        <div className="form-group d-flex align-items-center">
                            <label className="form-label me-1" htmlFor="Months">Months</label>
                            <input
                                type="checkbox"
                                id="Months"
                                checked={selectedCheckbox === "months"}
                                onChange={() => handleCheckboxChange("months")}
                            />
                        </div>
                        <div className="form-group d-flex align-items-center ms-3">
                            <select
                                className={`form-select ${errorMessage ? 'is-invalid' : ''}`}
                                value={remindTime}
                                onChange={handleChange1}
                                required
                            >
                                <option value="">Select Time</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            {errorMessage && <div className="invalid-feedback text-danger">{errorMessage}</div>}
                        </div>
                    </div>
                    <div className="form-group col-md-12 my-4">
                        <label className="form-label">Notes</label>
                        <textarea 
                            className="form-control"
                            placeholder="Enter notes ..."
                            value={formData.description}
                            onChange={handleDescriptionChange}
                        ></textarea>
                    </div>
                </div>
                <div className="col-2 m-auto">
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    </div>
                  
    );
}

SetReminder.propTypes = {
    leadOBJ: PropTypes.array.isRequired,
};
