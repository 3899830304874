
import React from "react";

export default function AddFollowUp() {
    return (
        <div 
            className="offcanvas offcanvas-end custom-offcanvas"
            tabIndex="-1"
            id="offcanvasAdd"
            aria-labelledby="offcanvasRightLabel"
        >

            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 450px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>
            <div className="offcanvas-header">
                                <div className="page-title d-flex">
                                    <h4>Add FolllowUp</h4>
                                </div>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="offcanvas-body ms-2">
                                <form action="brand-list.html">
                                    <div className="row">
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Organization</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Phone</label>
                                            <input type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Source</label>
                                            <input type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Last Contact</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Created</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label className="form-label">Notes</label>
                                            <textarea className="form-control" ></textarea>
                                        </div>
                                    </div>
                                    <div className="modal-footer-btn">
                                        <button type="button" className="btn btn-cancel me-2" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
    );
}