import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../Config/swal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "../../Config/swal.css";
import { useLabelContext } from "../../../Context";

export default function ShowInternalDeliveryNote() {
  
  const { ID } = useLabelContext();
  const PurchaseID = ID;
  

  const [userDetails, SetUserDetails] = useState([]);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  const [estimatePOData, setEstimatePOData] = useState({});
  const [itemsPOData, setItemsPOData] = useState([]);

  const [selectedCustomerid, setSelectedCustomerid] = useState("");
  const [selectedprojectid, setSelectedProjectid] = useState("");
  const [selectedStatusid, setSelectedStatusid] = useState("");
  const [selectedStaffid, setSelectedStaffid] = useState("");
  const[details,setDetails]=useState([])

  const [customersData, setCustomersData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);
  const [warehouesData, setWarehouseData] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    id: PurchaseID,
    note_name: "",
    accounting_date: "",
    total_amount : "",
    voucher_date: "",
    note_number: "",
    staffid: "",
    description: "",
    items: [],
   
  });

  useEffect(() => {
    if (PurchaseID) {
      sessionStorage.setItem("purchaseId", JSON.stringify(PurchaseID));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_BASE_CRM_URL}inventory/getinternaldeliverydetails?ID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data.ResultData.items)
        console.log(data.ResultData.details)
        if (data.Status) {
          setLoading(false);
          setDetails(data.ResultData.details[0]);
          setItemsPOData(data.ResultData.items);
        //   if (data && data.ResultData && data.ResultData.details) {
        //   setFormValues({
        //     ...formValues,
        //     id: data.ResultData.details.id || "",
        //     note_name: data.ResultData.details[0].internal_delivery_name ,
        //     accounting_date: data.ResultData.details[0].date_c
        //     || "",
        //     total_amount :data.ResultData.details[0].totalAmount || "",
        //     voucher_date: data.ResultData[0].date_add
        //     || "",
        //     note_number: data.ResultData[0].internal_delivery_code || "",
        //     staffid: data.ResultData[0].staff_id
        //     || "",
        //     description: data.ResultData[0].description || "",
        //   });
        // }
        console.log("FORM VALUES",formValues)

        //   setSelectedCustomerid(data.ResultData.estimate.clientid || "");
          setSelectedProjectid(data.ResultData.estimate.project_id || "");
          setSelectedStaffid(data.ResultData.details.staffid || "");
          setSelectedStatusid(data.ResultData.estimate.status || "");
        } else {
          setLoading(false);
          console.error("Fetched data is not an array:", data);
          setEstimatePOData([]);
          setItemsPOData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [PurchaseID]);

 

  // const defaultTax = taxesData.find(item => item.taxrate === 9);

  // Initialize the selected tax with the default tax (if it exists)
  //   const [selectedTax, setSelectedTax] = useState(defaultTax ? defaultTax.id : null);

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomerid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      clientid: selectedOption,
    }));
  };

  const handleProjectChange = (selectedOption) => {
    setSelectedProjectid(selectedOption);
    setFormValues((prevData) => ({
      ...prevData,
      project_id: selectedOption,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //get customers
  useEffect(() => {
    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const data = await response.json();
        if (data.Status) {
          setData(data.ResultData);
        } else {
          console.error(`Fetched data from ${url} is not an array:`, data);
          setData([]);
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData(`${API_BASE_CRM_URL}GetCustomers`, setCustomersData);
    fetchData(`${API_BASE_CRM_URL}GetStaff`, setStaffData);
    fetchData(`${API_BASE_CRM_URL}GetTaxes`, setTaxesData);
    fetchData(`${API_BASE_CRM_URL}GetProducts`, setProductsData);
    const getWarehouse = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${API_BASE_CRM_URL}GetWarehouse`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
  
          if (data.Status) {
            setWarehouseData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setWarehouseData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      getWarehouse()
  }, []);

  //get projects by Customer ID
  useEffect(() => {
    if (selectedCustomerid) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.Status) {
            // console.log(data.ResultData)
            setProjectsData(data.ResultData);
          } else {
            console.error("Fetched data is not an array:", data);
            setProjectsData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedCustomerid]);

  const customerOptions =
    customersData &&
    customersData.map((item) => ({
      value: item.userid,
      label: item.company,
    }));
    
    const warehouseOptions =
    warehouesData &&
    warehouesData.map((item) => ({
      value: item.warehouse_id,
      label: item.warehouse_name,
    }));

  const projectOptions =
    projectsData &&
    projectsData.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const productOptions =
    productsData &&
    productsData.map((item) => ({
      value: item.long_description,
      label: item.description,
      rate: parseFloat(item.rate),
    }));

  const staffOptions =
    staffData &&
    staffData.map((item) => ({
      value: item.staffid,
      label: item.firstname + " " + item.lastname,
    }));



  const statusOptions = [
    { value: 1, label: "Draft" },
    { value: 2, label: "Sent" },
    { value: 5, label: "Expired" },
    { value: 3, label: "Declined" },
    { value: 4, label: "Accepted" },
  ];

  const handleStatusChange = (selectedone) => {
    setSelectedStatusid(selectedone);
    setFormValues((prevData) => ({
      ...prevData,
      status: selectedone,
    }));
  };

  const handleStaffChange = (selectedone) => {
    setSelectedStaffid(selectedone);
    setFormValues((prevData) => ({
      ...prevData,
      sale_agent: selectedone,
    }));
  };

  console.log(itemsPOData);


  useEffect(() => {
    if (itemsPOData && itemsPOData.length > 0) {
      setRows(
        itemsPOData.map((item) => {
          const rate = parseFloat(item.rate) || 0;
          const qty = parseFloat(item.qty) || 0;
          const taxPercentage = parseFloat(item.tax) || 9;

          const subtotal = rate * qty;
          const taxAmount = subtotal * (taxPercentage / 100);
          const totalAmount = subtotal + taxAmount;


          return {
            id: item.internal_delivery_id || null,
        
            from_stock_name:item.from_stock_name || "",
            
            to_stock_name: item.to_stock_name,
            quantity: item.quantities,
            available_quantity: item.available_quantity,
            unit_price: item.unit_price,
            into_money: item.into_money,
            product_name:item.commodity_name,
            note_details_id: item.internal_delivery_id
          };
        })
      );
    }
    console.log(rows)
  }, [itemsPOData]);


  const addRow = (product) => {
    if (rows.some((row) => row.description === product.label)) {
      Swal.fire({
        title: "Product already added",
        text: "This product is already in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const newRow = {
      description: product.label,
      long_description: product.value || "",
      qty: 1,
      rate: product.rate || 0,
      tax: 9, // Fixed tax rate 9%
      amount: 0,
      item_order: rows.length + 1,
    };

    // Calculate initial amount
    const subtotal = parseFloat(newRow.rate) * parseFloat(newRow.qty);
    const taxAmount = subtotal * (9 / 100);
    newRow.amount = (subtotal + taxAmount).toFixed(2);

    const updatedRows = [...rows, newRow];

    setRows(updatedRows);

    // Update total amount
    const updatedTotal = updatedRows.reduce(
      (sum, row) => sum + parseFloat(row.amount),
      0
    );
    setEstimatePOData((prevData) => ({
      ...prevData,
      total: updatedTotal.toFixed(2),
    }));
  };

  const handleProductChange = (value, option) => {
    addRow(option);
  };

 

  const handleInputChange = (index, field, value) => {
    setRows((prevRows) => {
      if (!prevRows[index]) return prevRows; // Defensive check
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index], [field]: value };

      const rate = parseFloat(updatedRow.rate) || 0;
      const qty = parseFloat(updatedRow.qty) || 0;
      const taxPercentage = 9; // Fixed tax rate 9%

      const subtotal = rate * qty;
      const taxAmount = subtotal * (taxPercentage / 100);
      const totalAmount = subtotal + taxAmount;

      updatedRow.amount = totalAmount.toFixed(2);
      updatedRows[index] = updatedRow;

      // Update total amount
      const updatedTotal = updatedRows.reduce(
        (sum, row) => sum + parseFloat(row.amount),
        0
      );
      setEstimatePOData((prevData) => ({
        ...prevData,
        total: updatedTotal.toFixed(2),
      }));

      return updatedRows;
    });
  };

  useEffect(() => {
    const calculateAmounts = () => {
      let subTotal = 0;
      let totalTaxAmount = 0;

      rows.forEach((row) => {
        const rate = parseFloat(row.rate) || 0;
        const qty = parseFloat(row.qty) || 0;
        const taxPercentage = 9;

        const rowSubtotal = rate * qty;
        const taxAmount = rowSubtotal * (taxPercentage / 100);

        subTotal += rowSubtotal;
        totalTaxAmount += taxAmount;

        // Update the row amount
        row.amount = (rowSubtotal + taxAmount).toFixed(2);
      });

      setSubTotalAmount(subTotal.toFixed(2));
      setTotalTaxAmount(totalTaxAmount.toFixed(2));
    };

    calculateAmounts();
  }, [rows]);

  const handleDelete = async (index, id) => {
    const requestData = {
      id: id,
      rel_type: "estimate",
      rel_id: PurchaseID,
    };
    console.log(requestData);

    try {
      const response = await fetch(`${API_BASE_CRM_URL}DeleteItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
      setRows(newRows);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };


  
  //region update purchase order
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(rows, "rows when sebuit");

    const updatedFormValues = {
      ...details,
      items:rows
   
    };

    console.log(updatedFormValues, "data sending to api");

    try {
      const response = await fetch(`${API_BASE_CRM_URL}inventory/editinternaldeliverynote`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormValues),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result, "response from api");
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Purchase Order Updated Successfully.`,
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/purchase-order");
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleDeliveryNote = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  console.log(details.staff_id);

  return (
    <Base>
      <div className="page-heade d-flex justify-content-between align-items-center mb-3">
        <div className="add-iem me-auto">
          <div className="">
            <h4>view Internal Delivery Note</h4>
            {/* <h6 style={{ fontSize: '14px', color: '#495057', fontWeight: '400' }}>Modify fields for edit purchase order</h6> */}
          </div>

        </div>
        <div className="table-top-hea ms-auto">
          <Link to="/view-internaldelivery">
            <button className="btn btn-primary">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </button>
          </Link>
        </div>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row m-2">
            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
            <label className="form-label">Delivery note name</label>
                <input
                  className="form-control"
                  type="text"
                  name="internal_delivery_name"
                  placeholder="Delivery Note"
                  value={details.internal_delivery_name || ""}
                
                />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Estimate Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date_c"
                  value={formatDate(details?.date_c)}
                  
                />
              </div>
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Expiry Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date_add"
                  value={formatDate(details?.date_add)}
                 
                />
              </div>


              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
              <label className="form-label">Delivery note number</label>
                <input
                  type="number"
                  className="form-control"
                  name="internal_delivery_code"
                  value={details.internal_delivery_code || ""}
                  
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              
              <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
              <label className="form-label">deliverer</label>
                <Select
                  showSearch
                  placeholder="Choose agent"
                  value={details.fullname|| null}
                //   onChange={handleStaffChange}
                //   filterOption={(input, option) =>
                //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                //   }
                //   options={staffOptions}
                //   style={{ height: '2.4rem' }}
                />
              </div>
              <label className="form-label">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="description"
                  value={details.description || ""}
                  onChange={handleDeliveryNote}
                  placeholder="Enter notes..."
                />
                  {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                <label className="form-label">Item</label>
                <Select
                  showSearch
                  placeholder="Select Item"
                //   value={selectedItemid || null}
                  onChange={handleProductChange}
                  filterOption={(input, option) =>
                    (option?.value ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={productOptions}
                  style={{ height: "2.4rem" }}
                />
              </div> */}

            
             
              <div
                className="col-12 my-3 table-responsive"
                style={{ maxHeight: "20rem", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top">
                    <tr>
                      <th>#</th>
                      <th>item</th>
                      <th>from warehouse</th>
                      <th>to warehouse</th>
                      <th>availble quantity</th>
                      <th>quantity</th>
                      <th>unit price</th>
                      <th>amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product name"
                            value={row.product_name}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            readOnly
                          />
                        </td>
                        <td>
                          <Select
                            showSearch
                            placeholder="From stock"
                            value={row.from_stock_name || null}
                            // onChange={handleFromstockChange}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={warehouseOptions}
                            style={{ height: "2.4rem", width: "8rem" }}
                            onChange={(value, option) =>
                              handleInputChange(
                                index,
                                "from_stock_name",
                                option.label
                              )
                            }
                          />
                        </td>
                        <td>
                          <Select
                            showSearch
                            placeholder="To stock"
                            value={row.to_stock_name || null}
                            // onChange={handleTOstockChange}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={warehouseOptions}
                            style={{ height: "2.4rem", width: "8rem" }}
                            onChange={(value, option) =>
                              handleInputChange(
                                index,
                                "to_stock_name",
                                option.label
                              )
                            }
                          />
                        </td>
                        <td>
                         
                          {row.available_quantity}
                        </td>
                        <td>{row.quantity}</td>
                        <td>{row.unit_price}</td>
                        <td>{row.into_money}</td>
                        <td>
                          <button
                            className="btn"
                            type="button"
                            onClick={() => handleDelete(index, row.id)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger fs-6"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12 my-3 text-end">
                <h5>Total Amount: {details?.total_amount}</h5>
              </div>
             
              <div className="d-flex flex-column col-md-2 col-lg-2 m-auto mt-2">
                <button className="btn btn-primary" type="submit">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Base>
  );
}
