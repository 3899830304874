import React, { useState, useEffect } from "react";
import Base from '../../Config/Base'
import { Link, Navigate } from 'react-router-dom'
import { Select, Tooltip } from 'antd';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";

const AddTask = () => {

    const [selectedAssignee, setSelectedAssignee] = useState([])
    const [selectedFollower, setSelectedFollower] = useState([])
    const [selectedRelatedTo, setSelectedRelatedTo] = useState(0)// for related to options

    const [relatedToData, setRelatedToData] = useState("")// for related to data dropdown
    const [selectedRelatedToData, setSelectedRelatedToData] = useState("")

    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")
    const [startDate, setStartDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [isPublic, setIsPublic] = useState(false);
    const [isBillable, setIsBillable] = useState(false);
    const [hourlyRate, setHourlyRate] = useState("")
    const [priority, setPriority] = useState("")
    const [repeatEvery, setRepeatEvery] = useState("")
    const [currentStaffId, setCurrentStaffId] = useState()


    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (userDetails) {
            const parsedUserDetails = JSON.parse(userDetails);
            console.log(parsedUserDetails);
            console.log(parsedUserDetails.staffid);
            setCurrentStaffId(parsedUserDetails.staffid)

        }
    }, []);

    const assigneeList = selectedAssignee.map(staffid => ({
        staffid,
        assigned_from: currentStaffId
    }));
    console.log(assigneeList)

    const followerList = selectedFollower.map(follower => ({
        staffid: follower
    }));
    console.log(followerList)






    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    }
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value)
    }
    const handleDueDateChange = (event) => {
        setDueDate(event.target.value)
    }

    const handlePublicChange = () => {
        setIsPublic(!isPublic);
    };

    const handleBillableChange = () => {
        setIsBillable(!isBillable);
    };

    const handleHourlyRateChange = (event) => {
        setHourlyRate(event.target.value)
    }

    const handleSelectedRelatedToData = (selectedone) => {
        setSelectedRelatedToData(selectedone);
    };


    const handleRepeatEvery = (selectedone) => {
        setRepeatEvery(selectedone)
    }

    // start priority


    const priorityOptions = [
        { "value": 1, "label": "Urgent" },
        { "value": 2, "label": "High" },
        { "value": 3, "label": "Medium" },
        { "value": 4, "label": "Low" },
    ];
    const handlePriorityChange = (selectedone) => {
        setPriority(selectedone);
    };
    //end priority

    const relatedToOptions = [
        { "value": 1, "label": "project" },
        { "value": 2, "label": "Invoice" },
        { "value": 3, "label": "Customer" },
        // { "value": 4, "label": "Estimate" },
        // { "value": 5, "label": "Contract" },
        // { "value": 6, "label": "Ticket" },
        { "value": 7, "label": "Expense" },
        { "value": 8, "label": "Lead" },
        // { "value": 9, "label": "Proposal" },
        // { "value": 10, "label": "Inventory receiving voucher" },
        // { "value": 11, "label": "Inventory delivery voucher" },
        { "value": 0, "label": " " },

    ];


    // start asignee
    const [assigneeData, setAssigneeData] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setAssigneeData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setAssigneeData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const AssigneeOptions = assigneeData && assigneeData.map((item) => ({
        value: item.staffid,
        label: item.firstname + " " + item.lastname,
    }));

    const handleAssigneeChange = (selectedone) => {
        setSelectedAssignee(selectedone);
    };
    //end assignee

    //start follower
    const [followersData, setFollowersData] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setFollowersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setFollowersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const FollowersOptions = followersData && followersData.map((item) => ({
        value: item.staffid,
        label: `${item.firstname} ${item.lastname}`,
    }));

    const handleFollowerChange = (selectedone) => {
        setSelectedFollower(selectedone);
    };
    //end follower

    const repeatEveryOptions = [
        { "value": 1, "label": "Week" },
        { "value": 2, "label": "2 Weeks" },
        { "value": 3, "label": "1 Month" },
        { "value": 4, "label": "2 Months" },
        { "value": 5, "label": "3 Months" },
        { "value": 6, "label": "6 Months" },
        { "value": 7, "label": "1 Year" },
        // { "value": 8, "label": "Custom" },
    ]

    const handleRelatedTo = (selectedone) => {
        console.log(selectedone)
        setSelectedRelatedTo(selectedone)
    }

    useEffect(() => {
        if (selectedRelatedTo === 1) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjects`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setRelatedToData(data.ResultData);
                        console.log(data.ResultData)
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setRelatedToData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
            console.log(relatedToData.ResultData)
        } else if (selectedRelatedTo === 2) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetInvoices`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setRelatedToData(data.ResultData);
                        console.log(data.ResultData)
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setRelatedToData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
            console.log(selectedRelatedTo.ResultData)
            console.log('Selected ID is 2');
        } else if (selectedRelatedTo === 3) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setRelatedToData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setRelatedToData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
            console.log(setSelectedRelatedTo.ResultData)
            console.log('Selected ID is 3');
        } else if (selectedRelatedTo === 4) {
            console.log('Selected ID is 4');
        } else if (selectedRelatedTo === 5) {
            console.log('Selected ID is 5');
        } else if (selectedRelatedTo === 6) {
            console.log('Selected ID is 6');
        } else if (selectedRelatedTo === 7) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetExpenses`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setRelatedToData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setRelatedToData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
            console.log('Selected ID is 7');
        } else if (selectedRelatedTo === 8) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetLeads`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setRelatedToData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setRelatedToData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
            console.log('Selected ID is 8');
        } else {
            console.log('Selected ID is out of range');
        }
    }, [selectedRelatedTo]);

    const relatedOptions = relatedToData && relatedToData.map((item) => {
        if (selectedRelatedTo === 1) {
            return {
                value: item.id,
                label: item.name,
            };
        } else if (selectedRelatedTo == 2) {

            return {
                value: item.id,
                label: item.clientid,
            };

        } else if (selectedRelatedTo == 3) {

            return {
                value: item.userid,
                label: item.company,
            };

        } else if (selectedRelatedTo === 7) {
            return {
                value: item.id,
                label: item.expense_name,
            };
        } else if (selectedRelatedTo === 8) {
            return {
                value: item.id,
                label: item.name,
            };
        }
        else {
            console.log('Selected ID is out of range');
            return null; // If out of range, returning null (or any fallback).
        }
    }).filter(option => option !== null); // Filter out null values if needed.

    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const taskData = {
            name: subject,
            description: description,
            priority: priority,
            startdate: startDate,
            duedate: dueDate,
            repeat_every: 7,
            status: 1,
            rel_id: selectedRelatedToData,
            rel_type: selectedRelatedTo,
            is_public: isPublic,
            billable: isBillable,
            hourly_rate: hourlyRate,
            assignments: assigneeList,
            followers: followerList,
        };

        console.log(taskData, 'data sending to api');

        try {
            const response = await fetch(`${API_BASE_CRM_URL}addTask`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(taskData),
            });
            console.log(JSON.stringify(taskData))

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result, 'rrrrrrrrrrrrrrrrrrr');

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Credit Notes Added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        Navigate('/tasks')
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };



    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Add New Task</h4>
                        <h6>Enter fields to add New Task</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/tasks' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div class="custom-filed my-2 ">
                                <div class="input-block add-lists d-flex">
                                    <label class="checkboxs">
                                        <input type="checkbox"
                                            checked={isPublic}
                                            onChange={handlePublicChange} />
                                        <span class="checkmarks mx-1"></span>
                                        <Tooltip
                                            placement="bottom"
                                            title="If you set this task to public will be visible for all staff members. Otherwise will be only visible to members who are assignees,followers,creator or administrators"
                                        >
                                            <p className="text-dark">Public</p>
                                        </Tooltip>
                                    </label>
                                    <label class="checkboxs">
                                        <input type="checkbox"
                                            checked={isBillable}
                                            onChange={handleBillableChange} />
                                        <span class="checkmarks mx-1"></span>Billable
                                    </label>
                                </div>

                                {/* <div class="drag-drop text-center mb-4">
                                    <div class="upload">
                                        <a href="#"><img src="assets/img/icons/drag-drop.svg" alt=""/></a>
                                        <p>Drag and drop a <a href="#">file to upload</a></p>
                                    </div>
                                    <input type="file" multiple=""/>
                                </div> */}
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Subject</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    // name='name'
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    placeholder='Enter Subject'
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Hourly Rate</label>
                                <input
                                    className='form-control'
                                    type='number'
                                    // name='name'
                                    value={hourlyRate}
                                    onChange={handleHourlyRateChange}
                                    placeholder='Enter Hourly Rate '

                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Priority</label>
                                <Select
                                    showSearch
                                    placeholder="Select Priority"
                                    value={priority || null}
                                    onChange={handlePriorityChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={priorityOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Due Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={dueDate}
                                    onChange={handleDueDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Repeat Every</label>
                                <Select
                                    showSearch
                                    placeholder="Selected Repetition"
                                    value={repeatEvery}
                                    onChange={handleRepeatEvery}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={repeatEveryOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Related To</label>
                                <Select
                                    showSearch
                                    placeholder="Select type"
                                    onChange={handleRelatedTo}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={relatedToOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className={`d-flex flex-column col-lg-4 col-md-4 mb-2 ${selectedRelatedTo === 0 ? 'd-none' : 'd-block'}`}>
                                <label className="form-label">
                                    {(() => {
                                        switch (selectedRelatedTo) {
                                            case 1:
                                                return < >project</>;
                                            case 2:
                                                return < >Invoice</>;
                                            case 3:
                                                return < >Customer</>;
                                            case 4:
                                                return < >Estimate</>
                                            case 5:
                                                return <>Contract</>;
                                            case 6:
                                                return <>Ticket</>;
                                            case 7:
                                                return <>Expense</>;
                                            case 8:
                                                return <>Lead</>;
                                            case 9:
                                                return <>Proposal</>;
                                            case 10:
                                                return <>Inventory receiving voucher</>;
                                            case 11:
                                                return <>Inventory delivery voucher</>;
                                            default:
                                                return < >invalid</>;
                                        }
                                    })()}
                                </label>
                                <Select
                                    showSearch
                                    placeholder="Select type"
                                    value={selectedRelatedToData || null}
                                    onChange={handleSelectedRelatedToData}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={relatedOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>

                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Assignees</label>
                                <Select
                                    showSearch
                                    placeholder="Select Assignee"
                                    value={selectedAssignee || null}
                                    onChange={handleAssigneeChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={AssigneeOptions}
                                    style={{ height: '2.4rem' }}
                                    mode="multiple"
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Followers</label>
                                <Select
                                    showSearch
                                    placeholder="Select Follower"
                                    onChange={handleFollowerChange}
                                    value={selectedFollower || null}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={FollowersOptions}
                                    style={{ height: '2.4rem' }}
                                    mode="multiple"

                                />
                            </div>
                            {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Tags</label>
                                <Select
                                    mode="tags"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="tag"
                                // onChange={handleChange}
                                />
                            </div> */}
                            {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Insert Checklist Templates</label>
                                <Select
                                    showSearch
                                    placeholder="Nothing Selected"
                                    // onChange={handleProductChange}
                                    // filterOption={(input, option) =>
                                    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    // }
                                    // options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> */}

                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder="Enter notes..."
                                />
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </Base>
    )
}

export default AddTask