
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

export default function EditPayment() {

    const { PaymentId } = useParams()

    const [userDetails, SetUserDetails] = useState([]);
    const  navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [selectedPaymentModeid, setSelectedPaymnetModeid] = useState("");

    const [paymentModesData, setPaymentModesData] = useState([]);

    const [description, setDescription] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [paymentData, setPaymentData] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [formValues, setFormValues] = useState({
        id: PaymentId,
        invoiceid: "",
        amount: "",
        paymentmode: "",
        paymentmethod: "",
        date: "",
        note: "",
        transactionid: ""
        // daterecorded: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetPaymentModes`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setPaymentModesData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setPaymentModesData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetPaymentDetails?PaymentId=${PaymentId}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setLoading(false);
                    setPaymentData(data.ResultData[0]);
                    setFormValues({
                        invoiceid: data.ResultData[0].invoiceid || '',
                        date: formatDate(data.ResultData[0].paymentdate) || '',
                        amount: data.ResultData[0].amount || '',
                        note: data.ResultData[0].paymentnote || '',
                        transactionid: data.ResultData[0].transactionid || '',
                        paymentmode: data.ResultData[0].paymentmode || '',
                    });

                    setSelectedPaymnetModeid(data.ResultData[0].paymentmode || '');
                } else {
                    setLoading(false);
                    console.error("Fetched data is not an array:", data);
                    setPaymentData([]);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const paymentModeOptions =  paymentModesData && paymentModesData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handlePaymentModeChange = (selectedOption) => {
        setSelectedPaymnetModeid(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            paymentmode: selectedOption
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);

        // const totalAmount = calculateTotal();

        // const purchaseData = {
        //     sent: 1,
        //     datesend: new Date().toISOString().split('T')[0],
           
        // };

        formValues.id = PaymentId;
        formValues.paymentmode = selectedPaymentModeid;

        console.log(formValues, 'data sending to api');
    
        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdateInvoicePaymentRecord`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result, 'rrrrrrrrrrrrrrrrrrr');
            if (result.Status) {
                setSubmitLoading(false);
                Swal.fire({
                    title: `Payment Updated Successfully.`,
                    icon: "success",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/payments')
                    }
                });
            } else {
                setSubmitLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setSubmitLoading(false);
            console.error('Error:', error);
        }
    };

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Edit Payment</h4>
                        <h6>Enter fields for edit invocie payment</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/payments' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                {loading ?
                        <p className="text-center my-5">
                            <div className="containers m-auto">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </p>
                    :
                    <form onSubmit={handleSubmit}>
                        <div className="row justify-content-between m-2">
                            <div className="col-md-4 col-lg-4">
                                <div className="d-flex flex-column col-12 mb-2">
                                    <label className="form-label">Amount</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter received amount"
                                        name="amount"
                                        value={formValues.amount}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="d-flex flex-column col-12 mb-2">
                                    <label className="form-label">Payment Mode</label>
                                    <Select
                                        showSearch
                                        placeholder="Choose payment mode"
                                        value={selectedPaymentModeid || null}
                                        onChange={handlePaymentModeChange}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={paymentModeOptions}
                                        style={{ height: '2.4rem' }}
                                    />
                                </div> 
                                <div className="d-flex flex-column col-12 mb-2">
                                    <label className="form-label">Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="date"
                                        value={formValues.date}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="d-flex flex-column col-12 mb-2">
                                    <label className="form-label">Transaction ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter transaction id"
                                        name="transactionid"
                                        value={formValues.transactionid}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="d-flex flex-column col-12">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="note"
                                        value={formValues.note}
                                        onChange={handleChange}
                                        placeholder="Enter notes..."
                                    />
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <h3 className="text-center">Receipt</h3>
                                <div className="row mt-3">
                                    <div className="col-md-6 col-lg-6 text-start">
                                        <p className="text-info fw-bold">{paymentData?.company}</p>
                                        <p>{paymentData?.city}</p>
                                        <p>{paymentData?.phonenumber}</p>
                                        {/* <p>{paymentData?.country}</p> */}
                                    </div>
                                    <div className="col-md-6 col-lg-6 text-end">
                                        <p className="text-primary fw-bold">{paymentData?.deleted_customer_name}</p>
                                        <p>{paymentData?.state}</p>
                                        <p>{paymentData?.zip}</p>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Payment Date:</td>
                                                <td>{paymentData?.paymentdate}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Mode:</td>
                                                <td>{paymentData?.paymentsModeName}</td>
                                            </tr>
                                            <tr>
                                                <td>Transaction Id</td>
                                                <td>{paymentData?.transactionid}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 my-3 text-center">
                                    <h5>Total Amount:</h5>
                                </div>
                                <div className="table-responsive">
                                    <h4>Payment For:</h4>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Inovice No</th>
                                                <th>Invoice Date</th>
                                                <th>Invoice Amount</th>
                                                <th>Payment Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{paymentData?.prefix}{paymentData?.invoiceNumber}</td>
                                                <td>{formatDate(paymentData?.date)}</td>
                                                <td>{paymentData?.total}</td>
                                                <td>{paymentData?.amount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{submitLoading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
}
                </div>
            </div>
        </Base>
    )
}