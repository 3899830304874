import React, { useRef, useState, useEffect } from "react";
import { Calendar } from "antd";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import "../../Config/swal.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const CustomCalendar = () => {
  const [userDetails, SetUserDetails] = useState([]);
  const [isOffcanvasVisible, setIsOffcanvasVisible] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
  });
  const [events, setEvents] = useState([]);
  const [editEvents, setEditEvents] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Format for input type="date"
  };

  useEffect(() => {
    const userDetailsSession = JSON.parse(
      sessionStorage.getItem("userDetails")
    );
    SetUserDetails(userDetailsSession);
  }, []);

  useEffect(() => {
    fetchEvents();
  }, []);

  const offcanvasRef = useRef(null);
  const offcanvasEditRef = useRef(null);
  // const offcanvasEditRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState("");

  const fetchEvents = async () => {
    const now = dayjs();
    const startOfMonth = now.startOf("month").format("YYYY-MM-DD");
    const endOfMonth = now.endOf("month").format("YYYY-MM-DD");
    console.log(startOfMonth);
    console.log(endOfMonth);

    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}GetEvents?startDate=${startOfMonth}&endDate=${endOfMonth}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (result.Status) {
        setEvents(result.ResultData);
        console.log(result.ResultData);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setEvents([]);
    }
  };

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const onDateSelect = (date) => {
    console.log(date);
    const formattedDate = date.format("DD-MM-YYYY");
    const formattedDates = date.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setEventDetails((prevState) => ({
      ...prevState,
      startDate: formattedDates,
    }));

    const filteredEvents = events.filter(
      (item) => formatDate(item.start) === formatDate(date)
    );
    // setEditEvents(filteredEvents);
    console.log(filteredEvents);

    const offcanvas = new window.bootstrap.Offcanvas(offcanvasRef.current);
    const offcanvasEdit = new window.bootstrap.Offcanvas(
      offcanvasEditRef.current
    );

    if (filteredEvents.length > 0) {
      offcanvasEdit.show();
      offcanvas.hide();
      setEditEvents({
        title: filteredEvents[0].title,
        description: filteredEvents[0].description,
        startDate: filteredEvents[0].start,
        endDate: filteredEvents[0].end,
        color: filteredEvents[0].color,
        eventid: filteredEvents[0].eventid,
        isstartnotified: filteredEvents[0].isstartnotified,
        userid: filteredEvents[0].userid,
        reminder_before_type: filteredEvents[0].reminder_before_type,
        reminder_before: filteredEvents[0].reminder_before,
        public: filteredEvents[0].public,
      });
      // console.log(editEvents);
      setIsOffcanvasVisible(false);
    } else {
      offcanvas.show();
      offcanvasEdit.hide();
      setIsOffcanvasVisible(true);
    }

    // if (offcanvasRef.current) {

    // }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setEditEvents((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const eventData = {
      title: eventDetails.title,
      description: eventDetails.description,
      userid: userDetails.staffid,
      start: `${eventDetails.startDate}`,
      end: `${eventDetails.endDate}`,
      public: true,
      color: "#FF5733",
      isstartnotified: true,
      reminder_before: 15,
      reminder_before_type: "minutes",
    };

    console.log(eventData, "data sending to API");

    try {
      const response = await fetch(`${API_BASE_CRM_URL}AddEvent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: "Event Added Successfully.",
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "An error occurred while submitting the request.",
        icon: "error",
      });
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    const eventData = {
      title: editEvents.title,
      description: editEvents.description,
      userid: editEvents.userid,
      start: `${editEvents.startDate}`,
      end: `${editEvents.endDate}`,
      public: editEvents.public,
      color: editEvents.color,
      isstartnotified: editEvents.isstartnotified,
      reminder_before: editEvents.reminder_before,
      reminder_before_type: editEvents.reminder_before_type,
    };

    console.log(eventData, "data sending to API");

    try {
      const response = await fetch(
        `${API_BASE_CRM_URL}UpdateEvent?eventid=${editEvents.eventid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: "Event Added Successfully.",
          icon: "success",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to submit request.",
          icon: "error",
          customClass: {
            title: "swal-title",
            content: "swal-text",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "An error occurred while submitting the request.",
        icon: "error",
      });
    }
  };


  const dateCellRender = (value) => {
    const dayEvents = events.filter(
      (event) =>
        dayjs(event.start).format("YYYY-MM-DD") === value.format("YYYY-MM-DD")
    );
    
    return (
      <div>
        {dayEvents.length > 0 && (
          <span>
            {dayEvents.map((event, index) => (
              <div key={index} style={{color:'red'}}>{event.title}</div>
            ))}
          </span>
        )}
      </div>
    );
  };

  return (
    <Base>
      <div className="content">
        <div className="page-header">
          <div className="row align-items-center w-100">
            <div className="col-lg-10 col-sm-12">
              <h3 className="page-title">Calendar</h3>
            </div>
            <div className="col-lg-2 col-sm-12 d-flex justify-content-end p-0">
              {isOffcanvasVisible ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasEvent"
                  aria-controls="offcanvasEvent"
                >
                  Create Event
                </button>
              ) : (
                <button className="btn btn-primary">Create Event</button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Calendar
              onPanelChange={onPanelChange}
              onSelect={onDateSelect}
              cellRender={dateCellRender}
            />
          </div>
        </div>
        <div
          // className="offcanvas offcanvas-end custom-offcanvas"
          className={`offcanvas offcanvas-end custom-offcanvas ${
            isOffcanvasVisible ? "show" : ""
          }`}
          tabIndex="-1"
          id="offcanvasEvent"
          aria-labelledby="offcanvasRightLabel"
          ref={offcanvasRef}
        >
          <style>
            {`
                            .offcanvas.custom-offcanvas {
                                width: 450px !important; /* Adjust this as needed */
                            }

                            @media (max-width: 576px) {
                                .offcanvas.custom-offcanvas {
                                    width: 70% !important; /* Adjust the percentage as needed */
                                }
                            }
                        `}
          </style>
          <div className="offcanvas-header">
            <h4 className="offcanvas-title" id="offcanvasRightLabel">
              Add Event
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  placeholder="Enter title"
                  value={eventDetails.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="text"
                  name="startDate"
                  className="form-control"
                  value={selectedDate}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  className="form-control"
                  value={eventDetails.endDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Enter notes..."
                  value={eventDetails.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="offcanvas"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary ms-3">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className="offcanvas offcanvas-end custom-offcanvas"
          tabIndex="-1"
          id="offcanvasEdit"
          aria-labelledby="offcanvasRightLabel"
          ref={offcanvasEditRef}
        >
          <style>
            {`
                            .offcanvas.custom-offcanvas {
                                width: 450px !important; /* Adjust this as needed */
                            }

                            @media (max-width: 576px) {
                                .offcanvas.custom-offcanvas {
                                    width: 70% !important; /* Adjust the percentage as needed */
                                }
                            }
                        `}
          </style>
          <div className="offcanvas-header">
            <h4 className="offcanvas-title" id="offcanvasRightLabel">
              Edit Event
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <form onSubmit={handleSubmitEdit}>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  placeholder="Enter title"
                  value={editEvents.title ? editEvents.title : ""}
                  onChange={handleEdit}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  className="form-control"
                  value={
                    editEvents.startDate
                      ? formatDateForInput(editEvents.startDate)
                      : ""
                  }
                  onChange={handleEdit}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  className="form-control"
                  value={
                    editEvents.endDate
                      ? formatDateForInput(editEvents.endDate)
                      : ""
                  }
                  onChange={handleEdit}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Enter notes..."
                  value={editEvents.description ? editEvents.description : ""}
                  onChange={handleEdit}
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="offcanvas"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary ms-3">
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default CustomCalendar;
