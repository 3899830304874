import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";
import '../../Config/swal.css';


export default function AddContact({ cusOBJ }) {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    UserId: cusOBJ?.userid || "",
    FirstName: "",
    LastName: "",
    Title: "",
    Email: "",
    PhoneNumber: "",
    Projectsite: "",
  });


  useEffect(() => {
    if (cusOBJ) {
      setFormData((prevData) => ({
        ...prevData,
        UserId: cusOBJ.userid || prevData.UserId,
      }));
    }
  }, [cusOBJ]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    console.log(formData, "data to api");
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_CRM_URL}AddCustomerContact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (result.Status) {
        setLoading(false);
        Swal.fire({
          title: `Contact Added Successfully.`,
          icon: "success",
          customClass: {
            title: 'swal-title',
            content: 'swal-text'
          }
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.reload();
          }
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to Request Submit.",
          icon: "error",
          customClass: {
            title: 'swal-title',
            content: 'swal-text'
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end custom-offcanvas"
      tabIndex="-1"
      id="offcanvasContact"
      aria-labelledby="offcanvasRightLabel"
    >
      <style>
        {`
            .offcanvas.custom-offcanvas {
                width: 450px !important; /* Adjust this as needed */
            }

            @media (max-width: 576px) {
                .offcanvas.custom-offcanvas {
                width: 70% !important; /* Adjust the percentage as needed */
                }
            }
            `}
      </style>
      <div className="offcanvas-header">
        <h4>Add Contact</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body ms-2">
        <form>
          <div className="row">
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                name="FirstName"
                placeholder="Enter frist name"
                value={formData.FirstName}
                onChange={handleChange}
                required
              />
            </div>
           
            <div className="col-md-6">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                name="LastName"
                placeholder="Enter last name"
                value={formData.LastName}
                onChange={handleChange}
                required
              />
            </div>
            </div>
            <div className="mb-3 col-12">
              <label className="form-label">Designation</label>
              <input
                type="text"
                className="form-control"
                name="Title"
                placeholder="Enter designation"
                value={formData.Title}
                onChange={handleChange}
                required
              />
            </div>
           
            <div className="mb-3 col-12">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                name="Email"
                placeholder="Enter Contact Email"
                value={formData.Email}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="PhoneNumber"
                  placeholder="Enter Contact Mobile"
                  value={formData.PhoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Project Site</label>
                <input
                  type="text"
                  className="form-control"
                  name="Projectsite" // Make sure the name is correct for this field
                  placeholder="Enter Project Site"
                  value={formData.Projectsite}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* <div className="mb-3 col-12">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                name="Password"
                placeholder="Enter Contact password"
                value={formData.Password}
                onChange={handleChange}
                autoComplete="new-password"  // or autoComplete="off"                
                required
              />
            </div> */}
          </div>
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

AddContact.propTypes = {
  cusOBJ: PropTypes.array.isRequired,
};
