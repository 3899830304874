import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Select, Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { useLabelContext } from "../../../Context";

const ViewTask = () => {
  //   const { TaskId } = useParams();
  const { ID } = useLabelContext();
  const TaskId = ID;

  const [loading, setLoading] = useState(false);
  const [taskDetailsData, setTaskDetailsData] = useState({});

  useEffect(() => {
    if (TaskId) {
      sessionStorage.setItem("purchaseId", JSON.stringify(TaskId));
    }

    const storedPurchaseId = sessionStorage.getItem("purchaseId");
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_BASE_CRM_URL}GetTaskByIdnew?ID=${storedPurchaseId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.Status) {
          setLoading(false);
          setTaskDetailsData(data.ResultData);
        } else {
          setLoading(false);
          console.error("Fetched data is not an array:", data);
          setTaskDetailsData([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [TaskId]);
  useEffect(() => {}, [taskDetailsData]);
  console.log(Number(taskDetailsData[0]?.rel_type));
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <Base>
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>View Task</h4>
            {/* <h6>Enter fields for add purchase order</h6> */}
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <Link to="/tasks" className="btn btn-added">
              <i className="fa-solid fa-arrow-left me-1"></i>Go to List
            </Link>
          </li>
        </ul>
      </div>

      <div className="card table-list-card">
        <div className="card-body">
          {loading ? (
            <p className="text-center my-5">
              <div className="containers m-auto">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </p>
          ) : (
            <form>
              <div className="row m-2">
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Subject</label>
                  <input
                    className="form-control"
                    value={taskDetailsData[0]?.name || "N/A"}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Hourly Rate</label>
                  <input
                    className="form-control"
                    value={taskDetailsData[0]?.hourly_rate || "N/A"}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Priority</label>
                  {(() => {
                    const priority = taskDetailsData[0]?.priority;
                    switch (priority) {
                      case 1:
                        return (
                          <span className="badge bg-outline-warning col-3">
                            Urgent
                          </span>
                        );
                      case 2:
                        return (
                          <span className="badge bg-outline-info col-3">
                            High
                          </span>
                        );
                      case 3:
                        return (
                          <span className="badge bg-outline-success col-3">
                            Low
                          </span>
                        );

                      default:
                        return (
                          <span className="badge bg-outline-dark col-3">
                            Unknown
                          </span>
                        );
                    }
                  })()}
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Start Date</label>
                  <input
                    className="form-control"
                    value={formatDate(taskDetailsData[0]?.startdate) || ""}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Due Date</label>
                  <input
                    className="form-control"
                    value={formatDate(taskDetailsData[0]?.duedate) || "N/A"}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Repeat Every</label>

                  {(() => {
                    const repeatEvery = taskDetailsData[0]?.repeat_every;

                    switch (repeatEvery) {
                      case 1:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"Week"}
                          />
                        );
                      case 2:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"2 Weeks"}
                          />
                        );
                      case 3:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"1 Month"}
                          />
                        );
                      case 4:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"2 Months"}
                          />
                        );
                      case 5:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"3 Months"}
                          />
                        );
                      case 6:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"6 Months"}
                          />
                        );
                      case 7:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"1 Year"}
                          />
                        );
                      // case 8:
                      //     return <input className='form-control' readOnly value={"Custom"} />;
                      default:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"N/A"}
                          />
                        );
                    }
                  })()}
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Related To</label>
                  {/* <Select
                                        showSearch
                                        placeholder="Select type"
                                        // onChange={handleProductChange}
                                        // filterOption={(input, option) =>
                                        //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        // }
                                        // options={productOptions}
                                        style={{ height: '2.4rem' }}
                                    /> */}
                  {(() => {
                    const relatedType = Number(taskDetailsData[0]?.rel_type);
                    switch (relatedType) {
                      case 1:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"project"}
                          />
                        );
                      case 2:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"Invoice"}
                          />
                        );
                      case 3:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"Customer"}
                          />
                        );
                      case 7:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"Expense"}
                          />
                        );
                      case 8:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"Lead"}
                          />
                        );
                      default:
                        return (
                          <input
                            className="form-control"
                            readOnly
                            value={"N/A"}
                          />
                        );
                    }
                  })()}
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Assignees</label>

                  <input
                    className="form-control"
                    value={
                      taskDetailsData[0]?.assigned_staff_ids_and_names
                        ? taskDetailsData[0].assigned_staff_ids_and_names
                            .split(",")
                            .map((item) => item.split(":")[1])
                            .join(", ")
                        : "N/A"
                    }
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                  <label className="form-label">Followers</label>
                  <input
                    className="form-control"
                    value={
                      taskDetailsData[0]?.follower_staff_ids_and_names
                        ? taskDetailsData[0].follower_staff_ids_and_names
                            .split(",")
                            .map((item) => item.split(":")[1])
                            .join(", ")
                        : "N/A"
                    }
                    readOnly
                  />
                </div>
                {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Insert Checklist Templates</label>
                                <Select
                                    showSearch
                                    placeholder="Nothing Selected"
                                    // onChange={handleProductChange}
                                    // filterOption={(input, option) =>
                                    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    // }
                                    // options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> */}

                <div className="d-flex flex-column col-lg-12 col-md-12 mb-2">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    value={taskDetailsData[0]?.description || "N/A"}
                    readOnly
                  />
                </div>

                <div className="d-flex flex-column col-lg-12 col-md-12 mb-2">
                  <h6 className="my-2">Comments</h6>
                  <label className="form-label">
                    Comment by:{taskDetailsData[0]?.comment_staff_name}
                  </label>
                  <textarea
                    className="form-control"
                    value={taskDetailsData[0]?.comments || "N/A"}
                    readOnly
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Base>
  );
};

export default ViewTask;
