
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";


export default function AddPurchaseOrder() {

    const [userDetails, SetUserDetails] = useState([]);
    const [totalTaxAmount, setTotalTaxAmount] = useState(0);
    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");
    const [selectedStatusid, setSelectedStatusid] = useState("");
    const [selectedStaffid, setSelectedStaffid] = useState("");
    const [referenceNo, setReferenceNo] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [taxesData, setTaxesData] = useState([]);

    const [description, setDescription] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [estimateDate, setEstimateDate] = useState('');
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const fetchData = async (url, setData) => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Failed to fetch data from ${url}`);
                }
                const data = await response.json();
                if (data.Status) {
                    setData(data.ResultData);
                } else {
                    console.error(`Fetched data from ${url} is not an array:`, data);
                    setData([]);
                }
            } catch (error) {
                console.error(`Error fetching data from ${url}:`, error);
            }
        };

        fetchData(`${API_BASE_CRM_URL}GetCustomers`, setCustomersData);
        fetchData(`${API_BASE_CRM_URL}GetStaff`, setStaffData);
        fetchData(`${API_BASE_CRM_URL}GetTaxes`, setTaxesData);
        fetchData(`${API_BASE_CRM_URL}GetProducts`, setProductsData);
    }, []);

    useEffect(() => {
        if (selectedCustomerid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setProjectsData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setProjectsData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedCustomerid]);

    const customerOptions = customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));
    console.log(customerOptions);

    const projectOptions = projectsData && projectsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    console.log(projectOptions)

    const productOptions = productsData && productsData.map((item) => ({
        value: item.long_description,
        label: item.description,
        rate: parseFloat(item.rate),
    }));

    const staffOptions = staffData && staffData.map((item) => ({
        value: item.staffid,
        label: item.firstname + " " + item.lastname,
    }));

    const taxOptions = taxesData && taxesData.map((item) => ({
        key: item.id,
        value: item.taxrate,
        label: item.name + " " + item.taxrate,
    }));

    const statusOptions = [
        { "value": 1, "label": "Draft" },
        { "value": 2, "label": "Sent" },
        { "value": 5, "label": "Expired" },
        { "value": 3, "label": "Declined" },
        { "value": 4, "label": "Accepted" },
    ];

    const handleCustomerChange = (selectedone) => {
        setSelectedCustomerid(selectedone);
    };

    const handleProjectChange = (selectedone) => {
        setSelectedProjectid(selectedone);
    };

    const handleStatusChange = (selectedone) => {
        setSelectedStatusid(selectedone);
    };

    const handleStaffChange = (selectedone) => {
        setSelectedStaffid(selectedone);
    };

    const handleEstimateDateChange = (event) => {
        setEstimateDate(event.target.value);
    };

    const handleReferenceNo = (event) => {
        setReferenceNo(event.target.value)
    }


    const handleExpiryDateChange = (event) => {
        setExpiryDate(event.target.value);
    };

    const addRow = (product) => {
        if (rows.some(row => row.description === product.label)) {
            Swal.fire({
                title: "Product already added",
                text: "This product is already in the list.",
                icon: "warning",
                confirmButtonText: "OK"
            });
            return;
        }

        const newRow = {
            description: product.label,
            long_description: product.value || '',
            qty: 1,
            rate: product.rate || 0,
            tax: 9, // Fixed tax rate 9%
            amount: 0,
            item_order: rows.length + 1
        };


        const subtotal = parseFloat(newRow.rate) * parseFloat(newRow.qty);
        const taxAmount = subtotal * (9 / 100);
        newRow.amount = (subtotal + taxAmount).toFixed(2);

        const updatedRows = [...rows, newRow];

        setRows(updatedRows);
    };

    const handleProductChange = (value, option) => {
        addRow(option);
    };

    const handleInputChange = (index, field, value) => {
        setRows(prevRows => {
            if (!prevRows[index]) return prevRows;  // Defensive check
            const updatedRows = [...prevRows];
            const updatedRow = { ...updatedRows[index], [field]: value };

            const rate = parseFloat(updatedRow.rate) || 0;
            const qty = parseFloat(updatedRow.qty) || 0;
            const taxPercentage = 9; // Fixed tax rate 9%

            const subtotal = rate * qty;
            const taxAmount = subtotal * (taxPercentage / 100);
            const totalAmount = subtotal + taxAmount;

            updatedRow.amount = totalAmount.toFixed(2);
            updatedRows[index] = updatedRow;

            return updatedRows;
        });
    };

    useEffect(() => {
        const calculateAmounts = () => {
            let subTotal = 0;
            let totalTaxAmount = 0;
            let totalAmount = 0;

            rows.forEach(row => {
                const rate = parseFloat(row.rate) || 0;
                const qty = parseFloat(row.qty) || 0;
                const taxPercentage = 9;

                const rowSubtotal = rate * qty;
                const taxAmount = rowSubtotal * (taxPercentage / 100);

                subTotal += rowSubtotal;
                totalTaxAmount += taxAmount;

                totalAmount += (subTotal + totalTaxAmount)

                // Update the row amount
                row.amount = (rowSubtotal + taxAmount).toFixed(2);
            });

            setSubTotalAmount(subTotal.toFixed(2));
            setTotalAmount(totalAmount.toFixed(2));
            setTotalTaxAmount(totalTaxAmount.toFixed(2));
        };

        calculateAmounts();
    }, [rows]);

    const handleDelete = async (index, id) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // const totalAmount = calculateTotal();

        const purchaseData = {
            sent: 0,
            datesend: new Date().toISOString().split('T')[0],
            datecreated: new Date().toISOString().split('T')[0],
            clientid: selectedCustomerid,
            project_id: selectedprojectid,
            prefix: "PO",
            number: "1000",
            number_format: 1,
            date: estimateDate,
            expirydate: expiryDate,
            currency: 1,
            subtotal: subTotalAmount,
            total_tax: totalTaxAmount,
            total: totalAmount,
            adjustment: 0.00,
            addedfrom: userDetails.staffid,
            status: selectedStatusid,
            clientnote: description,
            adminnote: description,
            discount_percent: 0.0,
            discount_total: "",
            discount_type: "percentage",
            invoiceid: null,
            invoiced_date: null,
            reference_no: referenceNo,
            sale_agent: selectedStaffid,
            billing_street: "",
            billing_city: "",
            billing_state: "",
            billing_zip: "",
            billing_country: 1,
            shipping_street: "",
            shipping_city: "",
            shipping_state: "",
            shipping_zip: "",
            shipping_country: 1,
            include_shipping: 1,
            show_shipping_on_estimate: 1,
            show_quantity_as: 1,
            is_expiry_notified: 0,
            products: rows,
        };

        console.log(purchaseData, 'data sending to api');

        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddPurchaseOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result, 'rrrrrrrrrrrrrrrrrrr');
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `  Successfully Saved.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/purchase-order')
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };


    return (
        <Base>

            <div className="page-heade d-flex justify-content-between align-items-center mb-3">
                <div className="add-iem me-auto">
                    <div className="">
                        <h4>Add Purchase Order</h4>
                        <h6 style={{ fontSize: '14px', color: '#495057', fontWeight: '400' }}>Enter fields for add purchase order</h6>
                    </div>
                </div>
                <div className="table-top-hea ms-auto">
                    <Link to='/purchase-order'>
                        <button className="btn btn-primary">
                            <i className="fa-solid fa-arrow-left me-1"></i>Go to List
                        </button>
                    </Link>
                </div>
            </div>

            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Customer</label>
                                <Select
                                    showSearch
                                    placeholder="Choose customer"
                                    value={selectedCustomerid || null}
                                    onChange={handleCustomerChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={customerOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Project</label>
                                <Select
                                    showSearch
                                    placeholder="Choose project"
                                    value={selectedprojectid || null}
                                    onChange={handleProjectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={projectOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label"> Client PO Number</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Reference no"
                                    value={referenceNo}
                                    onChange={handleReferenceNo}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    showSearch
                                    placeholder="Choose status"
                                    value={selectedStatusid || null}
                                    onChange={handleStatusChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={statusOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">PurchaseOrder Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={estimateDate}
                                    onChange={handleEstimateDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Valid Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={expiryDate}
                                    onChange={handleExpiryDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Sale Agent</label>
                                <Select
                                    showSearch
                                    placeholder="Choose agent"
                                    value={selectedStaffid || null}
                                    onChange={handleStaffChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={staffOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Product</label>
                                <Select
                                    showSearch
                                    placeholder="Choose product"
                                    onChange={handleProductChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="col-12 my-3 table-responsive" style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <table className="table table-bordered">
                                    <thead className="sticky-top">
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product name"
                                                        value={row.description}
                                                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        style={{ width: '5rem' }}
                                                        value={row.qty}
                                                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Rate"
                                                        style={{ width: '7rem' }}
                                                        value={row.rate}
                                                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    {/* <Select
                                                        showSearch
                                                        placeholder="Choose tax"
                                                        value={row.tax}
                                                        onChange={(value) => handleTaxChange(index, value)}
                                                        options={taxOptions.map(option => ({ ...option, key: option.key }))}
                                                        style={{ height: '2.4rem', width: '7rem' }}
                                                    /> */}
                                                    SGST 9.00 %
                                                </td>
                                                <td>{row.amount}</td>
                                                <td>
                                                    <button className="btn" onClick={() => handleDelete(index)}>
                                                        <FontAwesomeIcon icon={faTrashCan} className="text-danger fs-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 my-3 text-end">
                                <h5>Total Amount: {totalAmount}</h5>
                            </div>
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter notes..."
                                />
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    )
}